import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';



const ConfigAssignAdd = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);





    const [operatorID, setoperatorID] = useState("");
    const [assignID, setassignID] = useState("");
    const [operator, setoperator] = useState("");
    const [Assign, setAssign] = useState("");
    const [Selectedoperator, setSelectedoperator] = useState("");


    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    useEffect(() => {
        OperatorList();
    }, [])

    useEffect(() => {
        AssignList();
    }, [])

    const OperatorList = () => {
        API.post('mapOperatorlist/condition',).then((response) => {
            if (response.data.success == true) {

                setoperatorID(response.data.data);

            }
        });
    }

    const AssignList = () => {
        API.post('mapassignwebphoneconfig/condition',).then((response) => {
            if (response.data.success == true) {

                setassignID(response.data.data);

            }
        });
    }
    const handleAdd = () => {


        let request = {
            operator_id: operator,
            webphone_config_id: Assign,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        };
        API.post('assignwebphoneconfig/add ', request).then((response) => {
            if (response.data.success == true) {
                // const { data } = response;
                togglePopup();
                setPopupTitle("Add New Config");
                setPopupMsg("Config Created Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/Config");
            }
            else {
                // setTimeout(() => {

                //   }, 100);
                togglePopup();
                setPopupTitle("Add New Admin");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };


    const handleoperatorId = (e) => {
        setoperator(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedoperator(
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );

    };
    const handleAssignId = (e) => {
        setAssign(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedoperator(
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );

    };

    console.log(Selectedoperator);

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/AssignWebphoneConfigList')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Assign New Config</h5>
                        </div>



                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className=" col-md-6 col-lg-4 ">
                                            <label className="form-label">
                                                Operator
                                            </label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                id="Sale"
                                                onChange={handleoperatorId}
                                                defaultValue={operator}
                                            >
                                                <option style={{ display: "none" }} >Operator Name</option>
                                                {operatorID?.length > 0 && (
                                                    <>
                                                        {operatorID.map((operatorID) => (
                                                            <option
                                                                key={operatorID.operator_id}
                                                                value={operatorID.operator_id}
                                                            >
                                                                {operatorID.operator_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                )}
                                            </select>
                                        </div>

                                        <div className=" col-md-6 col-lg-4 ">
                                            <label className="form-label">
                                                Assign Config
                                            </label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                id="Sale"
                                                onChange={handleAssignId}
                                                defaultValue={operator}
                                            >
                                                <option style={{ display: "none" }} >Assign Config</option>
                                                {assignID?.length > 0 && (
                                                    <>
                                                        {assignID.map((assignID) => (
                                                            <option
                                                                key={assignID.webphone_config_id}
                                                                value={assignID.webphone_config_id}
                                                            >
                                                                {assignID.webphoneconfigusername}
                                                            </option>
                                                        ))}
                                                    </>
                                                )}
                                            </select>
                                        </div>



                                    </div>
                                </form>
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleAdd}>Submit</button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default ConfigAssignAdd;