import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
// import { useSelector } from 'react-redux';
import Loading from '../../Component/Loading/Loading';
//import CommonPopup from '../../Component/CommonPopup/CommonPopup';
//import FileBase64 from 'react-file-base64';
import adduser from '../../assets/img/adduser.jpg';
import PhoneInputs from 'react-phone-input-2';
import ls from 'local-storage';

const OperatorView = () => {
    const navigate = useNavigate();
    // const userData = useSelector(state => state.LoginReducer.payload);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [pNumber, setPNumber] = useState("");
    const [CompanyName, setCompanyName] = useState("");
    const [operatorEditView, setOperatorEditView] = useState("");
   
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(true)
   // const location = useLocation()
    const OperatorId = ls.get("AdminId");

   


    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        Operatorview();
    }, [])

    const Operatorview = () => {
        let request = {
            operator_id: OperatorId
        };
        API.post('operatorview/condition ', request).then((response) => {
            if (response.data.success == true) {
                setFirstName(response.data.data[0]?.first_name);
                setLastName(response.data.data[0]?.last_name);
                setUserName(response.data.data[0]?.user_name);
                setEmail(response.data.data[0]?.email);
                setPassword(response.data.data[0]?.Password);
                setPNumber(response.data.data[0]?.phone);
                setStatus(response.data.data[0]?.active);
                setOperatorEditView(response.data.data[0]);
                setCompanyName(response.data.data[0]?.company_name);
                setLoading(false);
            }
        });
    }

    

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">

                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/operator')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">View Operator</h5>
                        </div>
                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">

                            <div className="modal-body">
                                {loading ? <Loading /> :
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12 ">

                                                <div className="user-upload-btn-wrapper">
                                                   <img alt="" src={operatorEditView?.operator_image || adduser} ></img> 
                                                        

                                                </div>
                                            </div>
                                            <div className="col-lg-4 ">
                                                <label htmlFor="first-name" className="col-form-label">First Name</label>
                                                <input type="text" className="form-control" id="first-name" value={firstName} onChange={(e) => setFirstName(e.target.value)} disabled />
                                               
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="last-name" className="col-form-label">Last Name </label>
                                                <input type="text" className="form-control" id="last-name" value={lastName} onChange={(e) => setLastName(e.target.value)} disabled />
                                               
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="email" className="col-form-label">Email </label>
                                                <input type="text" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} disabled />
                                               
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="username" className="col-form-label">User Name </label>
                                                <input type="text" className="form-control" id="username" value={userName} onChange={(e) => setUserName(e.target.value)} disabled />
                                                
                                            </div>
                                            
                                            <div className="col-lg-4">
                                                <label htmlFor="password" className="col-form-label">Password </label>
                                                <input type="text" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} disabled />
                                               
                                            </div>
                                            <div className="col-lg-4">
                                                {/* <label htmlFor="phone-number" className="col-form-label">Phone Number <span className='mandatory ms-1'>*</span></label>
                                                <input type="text" className="form-control" id="phone-number" value={pNumber} onChange={(e) => setPNumber(e.target.value)} /> */}
                                                <label htmlFor="phone-number" className="col-form-label"> Phone</label>    
                                                 <PhoneInputs
                                                class="form-control mt-5"
                                                country={'us'}
                                                disableDropdown={true}
                                                disableCountryCode={true}
                                                onlyCountries={['us']}
                                                value={pNumber}
                                                onChange={(e) => setPNumber(e)}
                                                placeholder=""
                                                disabled
                                            ></PhoneInputs>
                                              
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="status" className="col-form-label">Status </label>
                                                <input type="text" className="form-control" id="status" value={status == 1 ? "Active" :"Inactive"} onChange={(e) => setStatus(e.target.value)} disabled/>
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="username" className="col-form-label">Company Name </label>
                                                <input type="text" className="form-control" id="username" value={CompanyName}  disabled />
                                                
                                            </div>
                                        </div>
                                    </form>}
                                <div className="modal-footer">
                                   
                                    <button type="button" className="btn form-close-btn"  onClick={() => navigate('/operator')}>Close</button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
export default OperatorView;