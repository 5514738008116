import React, { useState,useEffect } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
import { useNavigate } from 'react-router-dom';
import conference from '../../assets/img/conference.png';
import ls from 'local-storage';


const pageLimit = 10;
const Calls = () => {
    
const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const [customersInfo, setCustomersInfo] = useState("");
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    useEffect(() => {
        CustomersListInfo();
    }, [])
    


    const CustomersListInfo = () => {
        API.post('operatorcallstatus/condition',).then((response) => {
            if (response.data.success == true) {
                 setCustomersInfo(response.data?.data);
                 setTotalRecords(response.data.data?.length);
                setLoading(false);
            }

        });
    }

    const redirection = (id) => {
        
        navigate({ pathname: "/OperatorView" });
        ls.set("AdminId", id);
        console.log("AdminId",id);
    };
    const redirectioncustomer = (id) => {

        navigate({ pathname: "/CustomerView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };
    const MINUTE_MS = 5000;
     
    useEffect(() => {
   const interval = setInterval(() => {
    CustomersListInfo();
   }, MINUTE_MS);
   return () => clearInterval(interval);
 }, [])

    return(
<div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content-call">

                    <div className="row">
                    <h5 className="heading-text">Calls</h5>
                        <div className="common-heading-sec col-lg-12">
                            {/* <div className="me-auto">
                            <div className="search-bar">
                                <input type="search" className="form-control" placeholder="Search by name or id" />
                            </div>
                            <div className="dropdown-sec me-2">
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div>
                            </div>
                            <div className="add-btn" ><i className="fa-solid fa-plus me-2"></i>Add New Calls</div> */}
                        </div>

                        <div className="common-heading-sec col-lg-12">
                          
                        </div>
                        {loading ? <Loading /> :
                            <div className="rounded table-responsive">
                                <table className="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th> 
                                            <th>Operator Name</th>
                                            <th>Customer Name</th>
                                            <th>Assign Date&Time</th>
                                            <th>Call Status</th>
                                            {/* <th>Conference Status</th> */}
                                            <th className='action-right-fix-list'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customersInfo.length > 0 ? customersInfo.sort((a, b) => b.customer_id - a.customer_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                            .map((customersInfo, index) =>
                                                <tr key={index}>
                                                    {/* <td>{customersInfo.operator_id}</td>
                                                    <td>{customersInfo.operatorname}</td> */}
                                                    <td><a onClick={() => redirection(customersInfo.operator_id)}>{customersInfo.operator_id}</a></td>     
                                                    <td><a onClick={() => redirection(customersInfo.operator_id)}>{customersInfo.operatorname}</a></td>
                                                    <td><a onClick={() => redirectioncustomer(customersInfo.customer_id)}>{customersInfo.customername}</a></td>
                                                    <td>{customersInfo.assigndatetime}</td>
                                                    {customersInfo.call_status === null || customersInfo.call_status === "null"|| customersInfo.call_status === undefined|| customersInfo.call_status === "" ? <td>Call Not Start</td> : <td>{customersInfo.call_status}</td>}
                                                    {/* <td>{customersInfo.conference_status}</td> */}
                                                    {customersInfo.call_status === "In Call" ?    
                                                   <td className='action-right-fix-list'><a className="edit"  onClick={() =>{ window.location.href="https://qa-operator.fmautoauction.com/conferencecall.html"}} > <img src={conference} className="icon-img" />Conference</a></td>:
                                                   <> 
                                                    <td></td>
                                                    </> 
                                                }
                                                </tr>
                                            ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                       } 
                        <ul className="pagination">
                            <Pagination className=""
                                totalRecords={totalRecords}
                                pageLimit={pageLimit}
                                pageRangeDisplayed={1}
                                onChangePage={setCurrentPage}
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Calls;