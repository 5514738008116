import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import Pagination from 'reactjs-hooks-pagination';
import { Modal } from 'react-bootstrap';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';
import moment from 'moment';


const Customers = () => {
  const navigate = useNavigate();
  const [customersInfo, setCustomersInfo] = useState("");
  const [loading, setLoading] = useState(true)
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,] = useState("");
  const [PageNn, setPageNo] = useState("");

  const pageLimit = PageNn;



  const [popupTitle,] = useState("");
  const [popupMsg,] = useState("");
  const [popupType,] = useState("");
  const [popupActionType,] = useState("");
  const [popupActionValue,] = useState("");
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [, setflag] = useState(false);
  //playpop

  const [editShow, setEditShow] = useState(false);
  // const [loadingOne, setLoadingOne] = useState(true);
  const [showPlayPause, setShowPlayPause] = useState(false);
  const [playFlag, setPlayFlag] = useState(false);
  const [, setCount] = useState(0);
  const [seekValue, setSeekValue] = useState(0);
  const [currentRunMintTime, setCurrentRunMintTime] = useState(0);
  const [currentRunSecTime, setCurrentRunSecTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [songs, setSongs] = useState("");

  //filter
  const [CustomerName, setCustomerName] = useState("");
  const [Comment, setComment] = useState("");
  // const [phoneNo, setPhoneNo] = useState("");
  const [/* operatorName */, setOperatorName] = useState("");
  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [fromDateError, setFromDateError] = useState("");
  const [operatorName1, setOperatorName1] = useState("");
  const [CustomerName1, setCustomerName1] = useState("");
  const [operatorID, setoperatorID] = useState("");
  const [/* operator */, setoperator] = useState("");
  const [Selectedoperator, setSelectedoperator] = useState("");
  const [Counts, setCounts] = useState("");

  const [Status, setStatus] = useState("");
  const [Selectoperator, setSelectoperator] = useState("");
  const [StatusID, setStatusID] = useState("");


  //duration
  const [Duration, setDuration] = useState("");

  const togglePopup = () => {
    setIsOpenPopup(!isOpenPopup);
  };

  const [isOpen, setIsOpen] = useState(false);
  const recallfunction = (data) => {
    // console.log('reCalling==', data);
    setIsOpen(data);
  };

  // useEffect(() => {
  //     setCustomersInfo(customersInfo);
  // }, [customersInfo])

  useEffect(() => {
    CustomersListInfo();
  }, [])

  useEffect(() => {
    if (playFlag == true) {
      const timerId = setInterval(() => {
        setCount((count) => count + 1);
      }, 30000);

      return () => clearInterval(timerId);
    }


  }, [playFlag]);

  useEffect(() => {
    OperatorList();
  }, [])

  useEffect(() => {
    StatusList();
  }, [])

  const CustomersListInfo = () => {
    
    API.post('operatorcallhistory/condition').then((response) => {
      if (response.data.success == true) {
        setCustomersInfo(response.data?.data);
        setCounts(response.data.data1[0].count);
        setTotalRecords(response.data.data?.length);
        setDuration(response.data.data?.calldiffertime);

        setflag(false);
        setPageNo(10);
        response.data?.data.map((data, index) => {
          const touggle = document.getElementById(`touggle${index}`)
          if (touggle != null) {
            if (data.status === "Active") {
              touggle.checked = true;
            }
            else {
              touggle.checked = false;
            }
          }
        }
        )
        setLoading(false);

        // console.log("res", response.data.data);

      }

    });
  }

  const OperatorList = () => {
    API.post('operatorlist/condition',).then((response) => {
      if (response.data.success == true) {

        setoperatorID(response.data.data);

      }
    });
  }

  const StatusList = () => {
    API.post('callstatus/condition',).then((response) => {
      if (response.data.success == true) {
        setStatusID(response.data.data);

      }
    });
  }

  const handleEditShow = (data) => {
    setEditShow(true);
    //getViewStory(data);
    setSongs(data.record_url);
    setCustomerName1(data.customername);
    setComment(data.comments);
    setOperatorName1(data.operatorname)
    setShowPlayPause(false);
    setSeekValue(0);
    setCurrentRunMintTime(0);
    setCurrentRunSecTime(0);
    setCurrentTime(0);
    // setMin(0);
    // setSec(0)
  }
  // {testingmints}:{testingSec}
  // console.log(currentRunMintTime, testingmints, currentRunSecTime, testingSec, "duration1");

  const handleEditClose = () => {
    setEditShow(false);
    setShowPlayPause(false);
    setSeekValue(0);
    setCurrentRunMintTime(0);
    setCurrentRunSecTime(0);
    setCurrentTime(0);
    setMin(0);
    setSec(0)
  }


  const handleLoadedMetadata = () => {
    setMin(audioRef.current.duration);

  }

  const onPlaying = () => {
    setCurrentTime(audioRef.current.duration);
    const runTime = audioRef.current.currentTime;
    const mints = Math.floor(runTime % 3600 / 60);
    const sec = Math.floor(runTime % 3600 % 60);
    setCurrentRunMintTime(mints);
    setCurrentRunSecTime(sec);
    setSeekValue(
      (audioRef.current.currentTime / audioRef.current.duration) * 100
    );
    console.log("inside cond", mints + ":" + sec === Math.floor(currentTime % 3600 / 60) + ":" + Math.floor(currentTime % 3600 % 60));
    if (mints + ":" + sec === Math.floor(currentTime % 3600 / 60) + ":" + Math.floor(currentTime % 3600 % 60) && mints + ":" + sec !== 0 + ":" + 0) {
      console.log("if inside");
      setTimeout(() => {
        audioRef.current.currentTime = 0;
        setShowPlayPause(false);
        setSeekValue(0);
        setCurrentRunMintTime(0);
        setCurrentRunSecTime(0);
        setCurrentTime(0);
      }, 1000);

    }
  };
  const testingmints = Math.floor(currentTime % 3600 / 60);
  const testingSec = Math.floor(currentTime % 3600 % 60);

  const [min, setMin] = useState(currentTime)
  const [sec, setSec] = useState(0)
  const testingmints1 = Math.floor(min % 3600 / 60);
  const testingSec1 = Math.floor(min % 3600 % 60);

  console.log("testingmints1", testingmints1, "testingSec1", testingSec1, sec);


  // useEffect(() => {


  //   if (editShow == true || showPlayPause == false) {
  //     const value = audioRef.current?.duration
  //     console.log("values", value);
  //     setMin(testingmints)
  //     setSec(testingSec)
  //   }

  // }
  //   , [testingSec, testingmints, playFlag, showPlayPause])
  // console.log("min & sec", min, "=====", sec);

  // useEffect(() => {
  //   const audio = document.getElementById("audio");
  //   audio?.addEventListener("loadedmetadata", () => {
  //     setSec(audio.duration);
  //   });
  // }, []);


  const CustomerSearch = () => {
    let request = {
      data: search
    }

    API.post("customersearch/condition", request).then(response => {
      setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false } }));
      setCounts(response.data.data1[0].count);
      setTotalRecords(response.data.data?.length);
    });
  }
  // const getViewStory = () => {

  //     API.post("operatorcallhistory/condition", request).then(response => {
  //       setSongs(response.data.data[0].record_url);
  //     //   // setViewStory(response.data.data[0]);
  //     //   setImages(response.data.data[0]?.listcaption.map((data) => data.image));
  //     //   setCaptions(response.data.data[0]?.listcaption.map((data) => data.captions));
  //        setLoadingOne(false);
  //     });
  //   }

  useEffect(() => {
    if (search != "") {
      const timer = setTimeout(() => {
        CustomerSearch();
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else {
      CustomersListInfo();
    }
  }, [search]);


  const audioRef = useRef(null);
  // const audioPlayer = React.createRef(null);
  // const audioPlayerOne = React.createRef(audioPlayer)
  // console.log("audioPlayerOne", audioPlayer);
  const fwrd = () => {
    const seekto = audioRef.current.currentTime + 10;
    // console.log("fwrd", audioRef.current.currentTime + 10);
    audioRef.current.currentTime = seekto;
  };
  const bwrd = () => {
    const seekto = audioRef.current.currentTime - 10;
    audioRef.current.currentTime = seekto;
  };


  const play = () => {
    audioRef.current.play();
    setPlayFlag(true);
  };

  const pause = () => {
    audioRef.current.pause();
    setPlayFlag(false);
  };

  const disablePastDt = current => {
    return current.isSameOrAfter(fromdate);
  };

  const inputProps = {
    placeholder: "From Date",
    value: fromdate
  };
  const inputProps1 = {
    placeholder: "To Date",
    value: todate
  };

  const fromDate = (event) => {
    if (event._isAMomentObject == true) {
      setfromdate(event.format("YYYY-MM-DD"))
      settodate("");
    }
  }
  const toDate = (event) => {
    if (event._isAMomentObject == true) {
      settodate(event.format("YYYY-MM-DD"))
    }
  }

  const handleBuyerId = (e) => {
    setoperator(e.target.value);
    console.log("=========check", e.target.value);
    setSelectedoperator(
      operatorID.filter((data) => data.operator_id == e.target.value)[0]
    );
    console.log(
      "=========check123",
      operatorID.filter((data) => data.operator_id == e.target.value)[0]
    );
  };

  const customerFilter = () => {
    setFromDateError("")

    if (fromdate) {
      if (!todate) {
        setFromDateError("To Date is required")
        return;
      }

    }
    setLoading(true)
    let request = {
      customer_name: CustomerName,
      operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,
      call_status_id: !Selectoperator ? "" : Selectoperator.call_status_id,
      operatorname: "",
      from_date: fromdate,
      to_date: todate
    }

    API.post("operatorcallhistorysearch/condition", request).then(response => {
      setCustomersInfo(response.data?.data);
      setTotalRecords(response.data.data?.length);
      setCounts(response.data.data1[0].count);
      setLoading(false)
      document.getElementById("page").value = "10";
      setCurrentPage(1);
      setPageNo(10);
    });
  }

  const handleclear = () => {

    setOperatorName("");
    // setPhoneNo("");
    setoperatorID("");
    setStatusID("");
    setfromdate("");
    setCustomerName("");
    settodate("");
    setLoading(true);
    StatusList();
    CustomersListInfo();

    document.getElementById("page").value = "10";
    // document.getElementById("userName").value = "Select userName";
  }

  const redirection = (id) => {

    navigate({ pathname: "/OperatorView" });
    ls.set("AdminId", id);
    console.log("AdminId", id);
  };

  const redirectioncustomer = (id) => {

    navigate({ pathname: "/CustomerView" });
    ls.set("AdminId", id);
    console.log("AdminId", id);
  };

  const duration = Duration;
  console.log(duration, "duration");
  console.log(currentRunMintTime, testingmints, currentRunSecTime, testingSec, currentTime, "duration1");

  //sorting

  const [sorted, setSorted] = useState({ sorted: "id", reversed: false })

  const sortById = () => {
    console.log("sortById start");
    const usersCopy = [...customersInfo];
    usersCopy.sort((userA, userB) => {
      if (sorted.reversed) {
        console.log("sortById if");
        return userA.call_id - userB.call_id;
      }
      return userB.call_id - userA.call_id;
    });
    setCustomersInfo(usersCopy);
    setSorted({ sorted: "id", reversed: !sorted.reversed });
  };

  const sortByOperatorName = () => {
    const usersCopy = [...customersInfo];
    usersCopy.sort((userA, userB) => {
      if (sorted.reversed) {
        return userB.operatorname.localeCompare(userA.operatorname);
      }
      return userA.operatorname.localeCompare(userB.operatorname);
    });
    setCustomersInfo(usersCopy);
    setSorted({ sorted: "operator_name", reversed: !sorted.reversed });
  };

  const sortByCustomerName = () => {
    const usersCopy = [...customersInfo];
    usersCopy.sort((userA, userB) => {
      if (sorted.reversed) {
        return userB.customername.localeCompare(userA.customername);
      }
      return userA.customername.localeCompare(userB.customername);
    });
    setCustomersInfo(usersCopy);
    setSorted({ sorted: "customername", reversed: !sorted.reversed });
  };
  const sortByListName = () => {
    console.log("sortById start");
    const usersCopy = [...customersInfo];

    usersCopy.sort((userA, userB) => {
        userA = userA.list_name || '';
        userB = userB.list_name || '';
        if (sorted.reversed) {
            console.log("sortById if");
            return userB.localeCompare(userA);
        }

        return userA.localeCompare(userB);

    });
    setCustomersInfo(usersCopy);
    setSorted({ sorted: "list_name", reversed: !sorted.reversed });

};

  const sortByDate = () => {

    const usersCopy = [...customersInfo];
    usersCopy.sort((userA, userB) => {
      userA = userA.calldate || '';
      userB = userB.calldate || '';
      if (sorted.reversed) {
        return userB.localeCompare(userA);
      }

      return userA.localeCompare(userB);

    });
    setCustomersInfo(usersCopy);
    setSorted({ sorted: "id", reversed: !sorted.reversed });
  };

  const handleStatusId = (e) => {
    setStatus(e.target.value);
    console.log("=========check status", e.target.value);
    setSelectoperator(
      StatusID.filter((data) => data.call_status_id == e.target.value)[0]
    );
    console.log(
      "=========check123",
      StatusID.filter((data) => data.call_status_id == e.target.value)[0]
    );
  };

  function formatTime(value) {
    let local = ""
   // let offset = ""
    // let utc1 = ""
   // console.log(value, "yyyy1");
   value = value.replace(" ","T")
   value = value+"Z";
   console.log(value, "yyyy1");
    local = new Date(value);
   value =local.toLocaleString();
    //var local = new Date("2023-05-22 08:21:11");
    // offset = local.getTimezoneOffset();
    // value = new Date(local.getTime() - offset * 60000);
    // console.log(value, "yyy");
     value = moment(value).format('MMMM D, YYYY hh:mm A')
    // let utc2222 = utc1
    console.log(value, "yyyy");
    return value;
}



  return (
    <div className="wrapper">
      <SideBar sideBarOpen={isOpen} />
      <div id="content" className={!isOpen ? '' : 'active'}>
        <Header recallfunction={recallfunction} />
        <div className="main-content">
          <h5 className="heading-text">History</h5>
          <div className="row">
            <div className="col-xl-12">
              <div className="common-heading-sec">

                {/* <div className="search-bar">
                                <input type="search" className="form-control" placeholder="Search by name or id" />
                            </div>
                            <div className="dropdown-sec me-2">
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div> */}
                {/* <div className="search-bar">
                  <input type="text" className="form-control" value={operatorName} placeholder="Operator Name" onChange={(e) => setOperatorName(e.target.value)} />
                </div> */}
                <div className=" col-md-6 col-lg-2 search-bar">
                  {/* <label className="form-label">
                                        Operator
                                    </label> */}
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="Sale"
                    onChange={handleBuyerId}
                  >
                    <option style={{ display: "none" }}>Operator Name</option>
                    {operatorID?.length > 0 && (
                      <>
                        {operatorID.map((operatorID) => (
                          <option
                            key={operatorID.operator_id}
                            value={operatorID.operator_id}
                          >
                            {operatorID.operatorname}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
                <div className=" col-md-6 col-lg-2 search-bar">
                  {/* <label className="form-label">
                                        Operator
                                    </label> */}
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="Sale"
                    onChange={handleStatusId}
                    defaultValue={Status}
                  >
                    <option style={{ display: "none" }} >Call Status</option>
                    {StatusID?.length > 0 && (
                      <>
                        {StatusID.map((StatusID) => (
                          <option
                            key={StatusID.call_status_id}
                            value={StatusID.call_status_id}
                          >
                            {StatusID.status_name}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
                <div className="search-bar">
                  <input type="text" className="form-control" value={CustomerName} placeholder="Customer Name" onChange={(e) => setCustomerName(e.target.value)} />
                </div>
                {/* <div className="search-bar">
                  <input type="text" className="form-control" value={phoneNo} placeholder="Search By PhoneNo" onChange={(e) => setPhoneNo(e.target.value)} />
                </div> */}
                <div className="datePickerBlock">
                  {/* <input type="date" className="form-control" value={fromdate} onChange={(e) => setfromdate(e.target.value)} /> */}
                  <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                    name="Date" onChange={fromDate} closeOnSelect={true}
                    id="meeting_date" />
                </div>
                <div className="datePickerBlock">
                  <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                    name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                    id="meeting_date" />
                  <div className="error-msgs">
                    <p className="form-input-error" >{fromDateError}</p>
                  </div>
                </div>

                <div className="search-clear" onClick={customerFilter}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>
              </div>
            </div>
            <div className="common-heading-sec col-xl-12 pageno">
              <span className="row-per-page">Rows per page:
                <span>
                  <div>
                    <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                    <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                    </select>
                  </div>
                </span>
                <div className="count">
                  <span> Total Call Count :{Counts}</span>
                </div>
              </span>
            </div>
            {loading ? <Loading /> :
              <>
                <div className="rounded table-responsive">
                  <table className="table bg-white">
                    <thead>
                      <tr>
                        <th onClick={sortById}>#<i className="fa fa-sort" aria-hidden="true"></i></th>
                        <th onClick={sortByOperatorName}>Operator Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                        <th onClick={sortByCustomerName}>Customer Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                        <th  onClick={sortByListName}>List Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                        <th onClick={sortByDate}>Date<i className="fa fa-sort" aria-hidden="true"></i></th>
                        <th >Start - End Time</th>
                        <th>Call Status</th>
                        <th>Didnot pickup count</th>
                        <th>Call Duration</th>
                        <th>Comments</th>
                        {/* <th>Status</th> */}



                        <th className='action-right-fix-list'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customersInfo.length > 0 ? customersInfo.sort((a, b) => b.customersInfo - a.customersInfo).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                        .map((customersInfo) =>
                          <tr key={customersInfo.call_id}>
                            <td>{customersInfo.call_id}</td>
                            <td><a onClick={() => redirection(customersInfo.operator_id)}>{customersInfo.operatorname}</a></td>
                            {/* <td>{customersInfo.customername}</td> */}
                            <td><a onClick={() => redirectioncustomer(customersInfo.customer_id)}>{customersInfo.customername}</a></td>
                            <td>{customersInfo.list_name}</td>
                            <td>{formatTime(customersInfo.datetimes)}</td>
                            <td>{customersInfo.start_time} - {customersInfo.end_time}</td>
                            <td>{customersInfo.status_name}</td>
                            {customersInfo.call_status_id === 2 ?
                              <td>{customersInfo.notpickupcount}</td> :
                              <>
                                <td></td>
                              </>
                            }
                            <td>{customersInfo.calldiffertime}</td>
                            <td>{customersInfo.comments}</td>
                            {/* <td>{customersInfo.active === 1 ?
                                                        <span className="active-color">Active</span> :
                                                        <span className="inactive-color">Inactive</span>
                                                    }</td> */}

                            <td className='action-right-fix-list' onClick={() => handleEditShow(customersInfo)} ><a className="edit">Play</a></td>
                          </tr>
                        ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                    </tbody>
                  </table>
                </div>
                <ul className="pagination">
                  <Pagination className=""
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageRangeDisplayed={1}
                    onChangePage={setCurrentPage}
                  />
                </ul>
              </>
            }

          </div>
        </div>
      </div>
      {/* --------------------------------Play popup----------------------------------- */}
      <Modal size="wrapper modal-dialog-centered modal-xl" show={editShow} onHide={handleEditClose}>
        <Modal.Body className='play' >
          {/* {loadingOne ? <Loading /> : */}
          <>
            {/* <div className="user-upload-btn-wrapper">
                 <img alt=""/> 
              </div> */}

            <div className="modal-body play">
              <h5 className="modal-title play" id="exampleModalLabel">History</h5>
              {/* <p className="caption-center">{caption}</p> */}
              {/* <audio controls id="audioval" onClick={getAudio}>
                      <source src={storyViewInfo.stories} type="audio/mp3" />
                    </audio> */}

              <div className="story-edit">
                <audio
                  src={songs}
                  ref={audioRef}
                  onTimeUpdate={onPlaying}
                  onLoadedMetadata={handleLoadedMetadata}
                  id="audio"
                  controlsg
                >
                </audio>
                <div className="audio-time">
                  <div className="audio-str-time">
                    {currentRunMintTime}:{currentRunSecTime}
                  </div>
                  {showPlayPause === false ?
                    <div className="audio-end-time">
                      {testingmints1}:{testingSec1}
                    </div> :
                    showPlayPause === true ?
                      <div className="audio-end-time">
                        {testingmints}:{testingSec}
                      </div> :
                      <div className="audio-end-time">
                        {testingmints1}:{testingSec1}
                      </div>}
                </div>
                <div className="range-slider">
                  <div className="slider-container">
                    <span className="bar"><span className="fill"></span></span>
                    <input
                      className="slider"
                      type="range"
                      min="0"
                      max="100"
                      step="0.5"
                      value={seekValue}
                      onChange={(e) => {
                        const seekto = audioRef.current.duration * (+e.target.value / 100);
                        //console.log("seekto==",(+seekValue / 100));
                        audioRef.current.currentTime = seekto;
                        setSeekValue(e.target.value);

                      }}
                    />
                  </div>
                </div>
                <div >
                  <div className="controlls_btns">
                    {currentRunSecTime > 0 ?
                      <button id="backward_btn" onClick={bwrd} ><i className="fa-solid fa-backward" ></i></button> :
                      <button id="backward_btn"  ><i className="fa-solid fa-backward" ></i></button>}
                    {showPlayPause === false ?
                      <button id="play_pause_btn" onClick={() => { play(); setShowPlayPause(true) }} ><i className="fa-solid fa-play" ></i></button> :
                      <button id="play_pause_btn" onClick={() => { pause(); setShowPlayPause(false); }} ><i className="fa-solid fa-pause" ></i></button>
                    }

                    <button id="forward_btn" onClick={fwrd} ><i className="fa-solid fa-forward" ></i></button>
                  </div>
                </div>
              </div>
              <form>
                <div className="row">
                  <div className="col-xl-4 mb-3">
                    <label htmlFor="main-category" className="col-form-label">Operator Name</label>
                    <input className="form-control" type="text" value={operatorName1} disabled />
                  </div>
                  <div className="col-xl-4 mb-3">
                    <label htmlFor="sub-category" className="col-form-label">Customer Name</label>
                    <input className="form-control" type="text" value={CustomerName1} disabled />
                  </div>
                  <div className="col-xl-4 mb-3">
                    <label htmlFor="sub-category" className="col-form-label">Comment</label>
                    <input className="form-control" type="text" value={Comment} disabled />
                  </div>
                </div>

              </form>

            </div>
          </>
          {/* } */}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleEditClose}>Close</button>
        </Modal.Footer>
      </Modal>
      {isOpenPopup && (
        <CommonPopup
          handleClose={togglePopup}
          popupTitle={popupTitle}
          popupMsg={popupMsg}
          popupType={popupType}
          popupActionType={popupActionType}
          popupActionValue={popupActionValue}
        />
      )}
    </div>
  );
};

export default Customers;