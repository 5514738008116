import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';



const Reschedule = () => {
    const [adminInfo, setAdminInfo] = useState("");
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [CustomerName, setCustomerName] = useState("");
    const [operatorName, setOperatorName] = useState("");
    const [/* userName */, setUserName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [fromDateError, setFromDateError] = useState("");
    const [PageNn, setPageNo] = useState("");

    const pageLimit = PageNn;

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        AdminList();
    }, [])
    // ============================List===============================
    const AdminList = () => {
        API.post('adminoperatorreschedulelist/condition',).then((response) => {
            if (response.data.success == true) {
                setAdminInfo(response.data.data);
                setTotalRecords(response.data.data?.length);
                document.getElementById("page").value = "10";
                setCurrentPage(1);
                setLoading(false);
                setPageNo(10);
            }
        });
    }

    // =========================Search============================

    const onSearch = (e) => {
        setSearch(e.target.value);
    }

    const adminSearch = () => {
        let request = {
            data: search
        }

        API.post("operatorreschedulelistsearch/condition", request).then(response => {
            setAdminInfo(response.data.data);
            setTotalRecords(response.data.data?.length);
            setCurrentPage(1);
        });
    }

    useEffect(() => {
        if (search != "") {
            const timer = setTimeout(() => {
                adminSearch();
            }, 200);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setLoading(true);
            AdminList();
        }
    }, [search]);

    const onKeydownAccountSearch = (event) => {
        if (event.key === "Enter") {
            adminSearch();
        }
    };
    const disablePastDt = current => {
        return current.isAfter(fromdate);
    };

    const inputProps = {
        placeholder: "From Date",
        value: fromdate
    };
    const inputProps1 = {
        placeholder: "To Date",
        value: todate
    };

    const fromDate = (event) => {
        if (event._isAMomentObject == true) {
            setfromdate(event.format("YYYY-MM-DD"))
            settodate("");
        }
    }
    const toDate = (event) => {
        if (event._isAMomentObject == true) {
            settodate(event.format("YYYY-MM-DD"))
        }
    }

    const adminFilter = () => {
        setFromDateError("")

        if (fromdate) {
            if (!todate) {
                setFromDateError("To Date is required")
                return;
            }

        }
        setLoading(true)
        let request = {
            customer_name: CustomerName,
            operator_name: operatorName,
            Phone: phoneNo,
            from_date: fromdate,
            to_date: todate
        }

        API.post("adminoperatorreschedulesearch/condition", request).then(response => {
            setAdminInfo(response.data.data);
            setTotalRecords(response.data.data?.length);
            setLoading(false)
            document.getElementById("page").value = "10";
            setCurrentPage(1);
            setPageNo(10);
        });
    }

    const handleSearch = () => {
        adminFilter();
    }

    const handleclear = () => {

        setOperatorName("");
        setCustomerName("");
        setUserName("");
        setPhoneNo("");
        // setfromdate("");
        // settodate("");
        //setPageNo("");
        setLoading(true);
        AdminList();
        // setFromDateError();
        document.getElementById("page").value = "10";
    }

    function formatMobileNO(value) {
        var x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        // console.log("value of x", x);
        value = '+1 ' + '(' + x[1] + ') ' + x[2] + '-' + x[3];
        //console.log("mobileno", value);
        return value;
    }
    const redirection = (id) => {

        navigate({ pathname: "/CustomerView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };

    const redirectionoperator = (id) => {

        navigate({ pathname: "/OperatorView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };

    //asperpage





    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <h5 className="heading-text">Reschedule</h5>

                    <div className="row">
                        <div className="col-xl-8">
                            <div className="common-heading-sec">
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={operatorName} placeholder="Operator Name" onChange={(e) => setOperatorName(e.target.value)} />
                                </div>
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={CustomerName} placeholder="Customer Name" onChange={(e) => setCustomerName(e.target.value)} />
                                </div>
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={phoneNo} placeholder=" Phone#" maxLength={10} onChange={(e) => setPhoneNo(e.target.value.replace(/\D/g, ""))} />
                                </div>

                                <div className="datePickerBlock">

                                    <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" onChange={fromDate} closeOnSelect={true}
                                        id="meeting_date" />
                                </div>


                                <div className="datePickerBlock">
                                    <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                                        id="meeting_date" />
                                    <div className="error-msgs">
                                        <p className="form-input-error" >{fromDateError}</p>
                                    </div>
                                </div>



                                <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                                <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>
                            </div>
                        </div>

                        <div className='col-xl-4'>
                            <div className='add-section'>
                                <div className="search-bar">
                                    <input type="search" className="form-control" value={search} placeholder="Name or  Phone# " onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                                </div>


                            </div>
                        </div>
                    </div>


                    <div className="common-heading-sec col-lg-12 pageno">
                        <span className="row-per-page">Rows per page:
                            <span>
                                <div>
                                    <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                    </select>
                                </div>
                            </span>
                        </span>

                    </div>

                    {loading ? <Loading /> :
                        <>
                            <div className="rounded table-responsive">

                                <table className="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Customer Name</th>
                                            <th>Phone#</th>
                                            <th>Operator Name</th>
                                            <th>Date & Time</th>
                                            <th>Status</th>
                                            <th className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {adminInfo?.length > 0 ? adminInfo.sort((a, b) => b.schedule_calls_id - a.schedule_calls_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                            .map((adminInfo, index) =>
                                                <tr key={index}>
                                                    <td>{adminInfo.schedule_calls_id}</td>
                                                    <td><a onClick={() => redirection(adminInfo.customer_id)}>{adminInfo.customername}</a></td>
                                                    <td>{formatMobileNO(adminInfo.Phone)}</td>
                                                    <td><a onClick={() => redirectionoperator(adminInfo.operator_id)}>{adminInfo.operatorname}</a></td>
                                                    <td>{adminInfo.assigndatetime}</td>
                                                    <td>{adminInfo.statusactive === 1 ?
                                                        <span className="active-color">Active</span> :
                                                        <span className="inactive-color">Inactive</span>
                                                    }</td>

                                                    {adminInfo.statusactive === 1 ?
                                                         <td className='text-center'><a className="edit me-2" onClick={() => navigate('/RescheduleEdit', { state: { Schedulecall: adminInfo.schedule_calls_id } })}>Edit</a>
                                                         <a className="edit" onClick={() => navigate('/RescheduleView', { state: { schedulecallsid: adminInfo.schedule_calls_id } })}>View</a></td> :
                                                        <>
                                                           <td className='text-center'> <a className="edit" onClick={() => navigate('/RescheduleView', { state: { schedulecallsid: adminInfo.schedule_calls_id } })}>View</a></td> 
                                                        </>
                                                    }

                                                   

                                                    {/* <td className='text-center'> <a className="edit" onClick={() => navigate('/RescheduleView', { state: { schedulecallsid: adminInfo.schedule_calls_id } })}>View</a></td> */}

                                                </tr>
                                            ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={setCurrentPage}

                                />
                            </ul>
                        </>
                    }


                </div>
            </div>
        </div>
    );
};
export default Reschedule;