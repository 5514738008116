import React, { useState , useEffect} from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';

import Loading from '../../Component/Loading/Loading';
import ls from 'local-storage';


const CompanyView = () => {
    const AdminId = ls.get("webphone_config_id");
    const navigate = useNavigate();
   // const userData = useSelector(state => state.LoginReducer.payload);
 
  
    const [Companyname, setCompanyname] = useState("");
    const [Description, setDescription] = useState("");
    const [State, setState] = useState("");
    const [City, setCity] = useState("");
    const [Zipcode, setZipcode] = useState("");
    const [status, setStatus] = useState("");

   // console.log("calllimit",calllimit);
   
    const [loading, setLoading] = useState(true)

   

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    // useEffect(() => {
    //     OperatorList();
    // }, [])

    // const OperatorList = () => {
    //     API.post('mapOperatorlist/condition',).then((response) => {
          

    //             setoperatorID(response.data.data);

           
    //     });
    // }

    useEffect(() => {
        Configview();
    }, [])

    const Configview = () => {
        let request = {
            company_id: AdminId,
        };
        API.post('companyview/condition ', request).then((response) => {
            if (response.data.success == true) {
               
                setCompanyname(response.data.data[0].company_name);
                setDescription(response.data.data[0].description);
                setState(response.data.data[0].state);
                setCity(response.data.data[0].city);
                setZipcode(response.data.data[0].zipcode);
                setStatus(response.data.data[0]?.active);
                setLoading(false);
            }
        });
    }



    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">
                   
                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/CompanyList')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">View Company</h5>
                        </div>



                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                            {loading ? <Loading /> :
                                <form>
                                    <div className="row">
                                       


                                        <div className="col-lg-4">
                                            <label htmlFor="Companyname" className="col-form-label">Company Name <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="Companyname" value={Companyname} onChange={(e) => setCompanyname(e.target.value)} disabled />
                                           
                                        </div>

                                        <div className="col-lg-4">

                                            <label htmlFor="first-name" className="col-form-label">Description </label>
                                            <input type="text" className="form-control" id="Description" value={Description}  disabled />
                                        </div>
                                        
                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">State </label>
                                            <input type="text" className="form-control" id="State" value={State}   disabled/>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">City </label>
                                            <input type="text" className="form-control" id="City" value={City}  disabled />
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">Zipcode </label>
                                            <input type="text" className="form-control" id="Zipcode" value={Zipcode}  disabled />
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="status" className="col-form-label">Status{/*  <span className='mandatory ms-1'>*</span> */}</label>
                                            <input type="text" className="form-control" id="Zipcode" value={status == "Active"||status == 1 ? "Active" :"Inactive"}  disabled />
                                        </div>
                                        
                                        
                                    </div>
                                </form>}
                                <div className="modal-footer">
                                    <button type="button" className="btn form-close-btn" onClick={() => navigate('/CompanyList')}>Close</button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
           
        </div>
    );
};
export default CompanyView;