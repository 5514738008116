import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
import API from '../../Services/BaseService';
import { useNavigate } from 'react-router-dom';
import ls from 'local-storage';
import Datetime from 'react-datetime';
import moment from 'moment';

const OperatorActivity = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    const [PageNn, setPageNo] = useState("");
    const pageLimit = PageNn;
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [customersInfo, setCustomersInfo] = useState("");
    //const [search, setSearch] = useState("");
    // const [customerName, setCustomerName] = useState("");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [fromDateError, setFromDateError] = useState("");
    // const [email, setEmail] = useState("");
    // const [phoneNo, setPhoneNo] = useState("");
    const [/* operatorName */, setOperatorName] = useState("");
    const [Calculate, /* setCalculate */] = useState([]);
    const [totalCount, /* settotalCount */] = useState("");
    const [TotDuration, /* setTotDuration */] = useState("");
    const [BreakTime, /* setBreakTime */] = useState("");
    // const [CallStatus, setCallStatus] = useState("");
    const [operatorID, setoperatorID] = useState("");
    const [/* operator */, setoperator] = useState("");
    const [Selectedoperator, setSelectedoperator] = useState("");
    const [Count, setCount] = useState("");




    useEffect(() => {
        CustomersListInfo();
    }, [])

    useEffect(() => {
        OperatorList();
    }, [])
    console.log("Calulate", Calculate);
    console.log("Calulate1", totalCount);
    console.log("Calulate2", TotDuration);
    console.log("Calulate3", BreakTime);

    const CustomersListInfo = () => {
        API.post('operatorreportlist/condition',).then((response) => {
            console.log("CustomersListInfo inside");
            if (response.data.success == true) {
                setCustomersInfo(response.data?.data1);
                // setCallStatus(response.data?.data1);
                setCount(response.data.data[0].count);
                // setCalculate(response.data?.data2.map(data => data.calldiffertime));
                // setTotDuration(response.data?.data3.map(data => data.total_time));
                // setBreakTime(response.data?.data4.map(data => data.total_time));
                // //setLoadValue(response.data.data2?.length);
                // settotalCount(response.data.data2?.length);
                 setTotalRecords(response.data.data1?.length);
                setLoading(false);
                //document.getElementById("page").value = "10";
                setCurrentPage(1);
                setPageNo(10);


                // console.log("res", response.data.data);

            }

        });
    }

    const OperatorList = () => {
        API.post('operatorlist/condition',).then((response) => {
            if (response.data.success == true) {
           
                setoperatorID(response.data.data);
               
            }
        });
    }
    const inputProps = {
        placeholder: "From Date",
        value: fromdate
    };
    const inputProps1 = {
        placeholder: "To Date",
        value: todate
    };
    // const onSearch = (e) => {
    //     setSearch(e.target.value);
    // }
    // const CustomerSearch = () => {
    //     let request = {
    //         data: search
    //     }

    //     API.post("operatorreportlistsearch/condition", request).then(response => {
    //         setCustomersInfo(response.data?.data);
    //         setTotalRecords(response.data.data?.length);
    //         setCurrentPage(1);
    //     });
    // }
    // useEffect(() => {
    //     if (search != "") {
    //         const timer = setTimeout(() => {
    //             CustomerSearch();
    //         }, 200);
    //         return () => {
    //             clearTimeout(timer);
    //         };
    //     } else {
    //         setLoading(true);
    //         CustomersListInfo();
    //     }
    // }, [search]);
    // const onKeydownAccountSearch = (event) => {
    //     if (event.key === "Enter") {
    //         CustomerSearch();
    //     }
    // };

    const redirection = (id) => {

        navigate({ pathname: "/OperatorView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };
    const redirectioncustomer = (id) => {

        navigate({ pathname: "/CustomerView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };

    const fromDate = (event) => {
        if (event._isAMomentObject == true) {
            setfromdate(event.format("YYYY-MM-DD"))
            settodate("");
        }
    }
    const toDate = (event) => {
        if (event._isAMomentObject == true) {
            settodate(event.format("YYYY-MM-DD"))
        }
    }

    const handleBuyerId = (e) => {
        setoperator(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedoperator(
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );
        console.log(
          "=========check123",
          operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );
      };

    const customerFilter = () => {
        setFromDateError("")

        if (fromdate) {
            if (!todate) {
                setFromDateError("To Date is required")
                return;
            }

        }
        setLoading(true)
        let request = {
            //customer_name: customerName,
            operatorname: "",
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,
            Phone: "",
            from_date: fromdate,
            to_date: todate
        }

        API.post("operatorreportlistsearch/condition", request).then(response => {
            setCustomersInfo(response.data?.data1);
           /*  setCalculate(response.data?.data2.map(data => data.calldiffertime)); */
            setCount(response.data.data[0].count);
            // setTotDuration(response.data?.data2.map(data => data.total_time));
            // setBreakTime(response.data?.data3.map(data => data.total_time));
            // settotalCount(response.data.data2?.length);
            setTotalRecords(response.data.data1?.length);
            setLoading(false)
            // document.getElementById("page").value = "10";
            setCurrentPage(1);
            setPageNo(10);
        });
    }

    const handleSearch = () => {
        customerFilter();
    }

    const handleclear = () => {

        // setCustomerName("");
        // setEmail("");
        // setPhoneNo("");
        setoperatorID("");
        setOperatorName("");
        setfromdate("");
        settodate("");
        setFromDateError("");
        setLoading(true);
        CustomersListInfo();
        // setCurrentPage(1);
        //document.getElementById("page").value = "10";

        // document.getElementById("userName").value = "Select userName";
    }
    const disablePastDt = current => {
        return current.isSameOrAfter(fromdate);
    };



    // //calculate

    //const historyList = Calculate;
    //const valuetime =  ;
    //console.log('valuetime',valuetime);

    // const zeroPad = (num) => {
    //     var str = String(num);
    //     if (str.length < 2) {
    //         return '0' + str;
    //     }
    //     return str;
    // }
    //console.log("valuetime", valuetime);
    // assuming your time strings will always be (H*:)(m{0,2}:)s{0,2} and never negative
    // function totalTimeString(timeStrings) {
    // const totalTimeString = (timeStrings) => {
    //     var totals = timeStrings.reduce(function (a, timeString) {
    //         console.log("timestring", timeStrings);
    //         var parts = timeString.split(':');
    //         var temp;

    //         if (parts.length > 0) {
    //             temp = Number(parts.pop()) + a.seconds;
    //             a.seconds = temp % 60;
    //             if (parts.length > 0) {
    //                 temp = (Number(parts.pop()) + a.minutes) + ((temp - a.seconds) / 60);
    //                 a.minutes = temp % 60;
    //                 a.hours = a.hours + ((temp - a.minutes) / 60);
    //                 if (parts.length > 0) {
    //                     a.hours += Number(parts.pop());
    //                 }
    //             }
    //         }
    //         console.log("result1", a);
    //         return a;
    //     }, {
    //         hours: 0,
    //         minutes: 0,
    //         seconds: 0
    //     });
    //     // returned string will be HH(H+):mm:ss
    //     return [
    //         zeroPad(totals.hours),
    //         zeroPad(totals.minutes),
    //         zeroPad(totals.seconds)
    //     ].join(':');
    // }

    //  var result1 = totalTimeString( 
    //     Calculate
    //  );
    //console.log("result1", result1, totalCount.toString(),);

    // const zeroPad1 = (num) => {
    //     var str = String(num);
    //     if (str.length < 2) {
    //         return '0' + str;
    //     }
    //     return str;
    // }

    // const totalTimeString1 = (timeStrings1) => {
    //     console.log("timeStringsinsde", timeStrings1);
    //     const newData = timeStrings1.filter((date) => date !== null);
    //     console.log("newData", newData);
    //     var totals = newData.reduce(function (a, timeString) {
    //         console.log("timestring1", timeString);

    //         var parts = timeString.split(':');
    //         var temp;

    //         if (parts.length > 0) {
    //             temp = Number(parts.pop()) + a.seconds;
    //             a.seconds = temp % 60;
    //             if (parts.length > 0) {
    //                 temp = (Number(parts.pop()) + a.minutes) + ((temp - a.seconds) / 60);
    //                 a.minutes = temp % 60;
    //                 a.hours = a.hours + ((temp - a.minutes) / 60);
    //                 if (parts.length > 0) {
    //                     a.hours += Number(parts.pop());
    //                 }
    //             }
    //         }
    //         console.log("result1", a);
    //         return a;
    //     }, {
    //         hours: 0,
    //         minutes: 0,
    //         seconds: 0
    //     });
    //     // returned string will be HH(H+):mm:ss
    //     return [
    //         zeroPad1(totals.hours),
    //         zeroPad1(totals.minutes),
    //         zeroPad1(totals.seconds)
    //     ].join(':');
    // }

    //breacktime
    // const zeroPad2 = (num) => {
    //     var str = String(num);
    //     if (str.length < 2) {
    //         return '0' + str;
    //     }
    //     return str;
    // }

    // const totalTimeString2 = (timeStrings2) => {
    //     console.log("timeStringsinsde", timeStrings2);
    //     const newData = timeStrings2.filter((date) => date !== null);
    //     console.log("newData", newData);
    //     var totals = newData.reduce(function (a, timeString) {
    //         console.log("timestring1", timeString);

    //         var parts = timeString.split(':');
    //         var temp;

    //         if (parts.length > 0) {
    //             temp = Number(parts.pop()) + a.seconds;
    //             a.seconds = temp % 60;
    //             if (parts.length > 0) {
    //                 temp = (Number(parts.pop()) + a.minutes) + ((temp - a.seconds) / 60);
    //                 a.minutes = temp % 60;
    //                 a.hours = a.hours + ((temp - a.minutes) / 60);
    //                 if (parts.length > 0) {
    //                     a.hours += Number(parts.pop());
    //                 }
    //             }
    //         }
    //         console.log("result1", a);
    //         return a;
    //     }, {
    //         hours: 0,
    //         minutes: 0,
    //         seconds: 0
    //     });
    //     // returned string will be HH(H+):mm:ss
    //     return [
    //         zeroPad2(totals.hours),
    //         zeroPad2(totals.minutes),
    //         zeroPad2(totals.seconds)
    //     ].join(':');
    // }

    function formatMobileNO(value) {
        var x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        // console.log("value of x", x);
        value = '+1 ' + '(' + x[1] + ') ' + x[2] + '-' + x[3];
        // console.log("mobileno", value);
        return value;
    }



    //sorting

    const [sorted, setSorted] = useState({ sorted: "id", reversed: false })

    const sortById = () => {
        console.log("sortById start");
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.operator_report_id - userB.operator_report_id;
            }
            return userB.operator_report_id - userA.operator_report_id;
        });
        //  usersCopy.sort((userA, userB) =>
        // userA.operator_report_id > userB.operator_report_id ? (userB.operator_report_id - userA.operator_report_id) : (userA.operator_report_id - userB.operator_report_id))
       
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "id", reversed: !sorted.reversed });
    
    };

    const sortByOperatorName = () => {
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.operatorname.localeCompare(userA.operatorname);
            }
            return userA.operatorname.localeCompare(userB.operatorname);
        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "operatorname", reversed: !sorted.reversed });
    };

    const sortByOperatorActivity = () => {
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.operator_call_status.localeCompare(userA.operator_call_status);
            }
            return userA.operator_call_status.localeCompare(userB.operator_call_status);
        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "operator_call_status", reversed: !sorted.reversed });
    };

    const sortByCustomerName = () => {
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            userA = userA.customerusername || '';
            userB = userB.customerusername || '';
            if (sorted.reversed) {
                return userB.localeCompare(userA);
            }
        
            return userA.localeCompare(userB);
        
        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "customerusername", reversed: !sorted.reversed });
    };

    const sortByListName = () => {
        console.log("sortById start");
        const usersCopy = [...customersInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.list_name || '';
            userB = userB.list_name || '';
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "list_name", reversed: !sorted.reversed });

    };

    const sortByPhone = () => {
        console.log("sortById start");
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.customer_phone - userB.customer_phone;
            }
            return userB.customer_phone - userA.customer_phone;
        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "customer_phone", reversed: !sorted.reversed });
    };

    const sortByDatetime = () => {
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.reportdatetimes.localeCompare(userA.reportdatetimes);
            }
            return userA.reportdatetimes.localeCompare(userB.reportdatetimes);
        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "reportdatetimes", reversed: !sorted.reversed });
    };

   function formatTime(value) {
        let local = ""
       // let offset = ""
        // let utc1 = ""
       // console.log(value, "yyyy1");
       value = value.replace(" ","T")
       value = value+"Z";
       console.log(value, "yyyy1");
        local = new Date(value);
       value =local.toLocaleString();
        //var local = new Date("2023-05-22 08:21:11");
        // offset = local.getTimezoneOffset();
        // value = new Date(local.getTime() - offset * 60000);
        // console.log(value, "yyy");
         value = moment(value).format('MMMM D, YYYY hh:mm A')
        // let utc2222 = utc1
        console.log(value, "yyyy");
        return value;
    }

   


    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content-report">
                    <h5 className="heading-text">Operator Activity</h5>

                    <div className="row">
                        <div className="col-xl-8">
                            <div className="common-heading-sec">
                                {/* <div className="search-bar">
                                    <input type="text" className="form-control" value={customerName} placeholder="Customer Name" onChange={(e) => setCustomerName(e.target.value)} />
                                </div> */}
                                {/* <div className="search-bar">
                                    <input type="text" className="form-control" value={operatorName} placeholder="Operator Name" onChange={(e) => setOperatorName(e.target.value)} />
                                </div> */}

                                <div className=" col-md-6 col-lg-3 search-bar">
                                    {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        id="Sale"
                                        onChange={handleBuyerId}
                                    >
                                        <option style={{ display: "none" }}>Operator Name</option>
                                        {operatorID?.length > 0 && (
                                            <>
                                                {operatorID.map((operatorID) => (
                                                    <option
                                                        key={operatorID.operator_id}
                                                        value={operatorID.operator_id}
                                                    >
                                                        {operatorID.operatorname}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </select>
                                </div>
                                {/* <div className="search-bar">
                                    <input type="text" className="form-control" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={phoneNo} placeholder="Phone#" onChange={(e) => setPhoneNo(e.target.value.replace(/\D/g, ""))} />
                                </div> */}
                                <div className="datePickerBlock">
                                    {/* <input type="date" className="form-control" value={fromdate} onChange={(e) => setfromdate(e.target.value)} /> */}
                                    <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" onChange={fromDate} closeOnSelect={true}
                                        id="meeting_date" />
                                </div>
                                <div className="datePickerBlock">
                                    <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                                        id="meeting_date" />
                                    <div className="error-msgs">
                                        <p className="form-input-error" >{fromDateError}</p>
                                    </div>
                                </div>

                                <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                                <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>

                            </div>
                            {/* <div className="search-bar">
                                <input type="search" className="form-control" placeholder="Name or Id or Phone#" onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                            </div> */}

                        </div>
                    </div>

                    {loading ? <Loading /> :
                        <>
                            {/* <section className="dashboard-sec">
                                <div className="row">

                                    <div className="custom-column">
                                        <div className="dash-outer-box">
                                            <h3>Total Calls</h3>
                                            <div className="box-content">
                                                <div className="dast-title">
                                                    <h6>{totalCount}</h6>
                                                   
                                                </div>
                                                <div className="dash-icon dash-icon-color1">
                                                    <i className="fa-solid fa-users"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-column">
                                        <div className="dash-outer-box">
                                            <h3>Call Duration</h3>
                                            <div className="box-content">
                                                <div className="dast-title">
                                                    <h6>{totalTimeString(Calculate)}</h6>
                                                 
                                                </div>
                                                <div className="dash-icon dash-icon-color2">
                                                    <i className="fa-solid fa-headset"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-column">
                                        <div className="dash-outer-box">
                                            <h3>Login Duration</h3>
                                            <div className="box-content">
                                                <div className="dast-title">
                                                    <h6>{totalTimeString1(TotDuration)}</h6>
                                                   
                                                </div>
                                                <div className="dash-icon dash-icon-color3">

                                                    <i className="fa-solid fa-user"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-column">
                                        <div className="dash-outer-box">
                                            <h3>Break Time</h3>
                                            <div className="box-content">
                                                <div className="dast-title">
                                                    <h6>{totalTimeString2(BreakTime)}</h6>
                                                  
                                                </div>
                                                <div className="dash-icon dash-icon-color4">
                                                    <i className="fa-solid fa-phone-slash"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   

                                </div>

                            </section> */}
                            <div className="common-heading-sec col-xl-12 pageno">
                                <span className="row-per-page">Rows per page:
                                    <span>
                                        <div>
                                            <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                            <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                            </select>
                                        </div>
                                    </span>
                                    <div className="count">
                            <span> Total Count :{Count}</span>
                        </div>
                                </span>
                            </div>

                            <div className="rounded table-responsive">
                                <table className="table bg-white">
                                    <thead>
                                        <tr>
                                            <th onClick={sortById}>Id#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByOperatorName}>Operator Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByOperatorActivity}>Operator Activity<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByCustomerName}>Customer Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByListName}>List Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByPhone}>#Phone<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByDatetime}>Date&Time<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th>Call Status</th>
                                            <th>Call Duration</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customersInfo.length > 0 ? customersInfo.sort((a, b) => b.customersInfo - a.customersInfo).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                            .map((customersInfo, index) =>
                                                <tr key={index}>
                                                    {/* <td>{customersInfo.operator_report_id}</td>
                                                    <td>{customersInfo.operatorname}</td> */}
                                                    <td>{customersInfo.operator_report_id}</td>
                                                    <td><a onClick={() => redirection(customersInfo.operator_id)}>{customersInfo.operatorname}</a></td>

                                                    <td>{customersInfo.operator_call_status}</td>

                                                    <td> {customersInfo.customerusername === null || customersInfo.customerusername === "null" ? <td></td> : <a onClick={() => redirectioncustomer(customersInfo.customer_id)}><td>{customersInfo.customerusername}</td></a>}</td>
                                                    <td>{customersInfo.list_name}</td>
                                                    {customersInfo.customer_phone === null || customersInfo.customer_phone === "null" ? <td></td> : <td>{formatMobileNO(customersInfo.customer_phone)}</td>}
                                                    <td>{formatTime(customersInfo.datecreatedtime)}</td>
                                                    {/* <td>{customersInfo.reportdatetimes}</td> */}
                                                    <td> {customersInfo.status_name === null || customersInfo.status_name === "null" || customersInfo.status_name === "" ? <td></td> : <td>{customersInfo.status_name}</td>}</td>
                                                    <td> {customersInfo.calls_duration === null || customersInfo.calls_duration === "null" || customersInfo.calls_duration === "" ? <td></td> : <td>{customersInfo.calls_duration}</td>}</td>


                                                </tr>
                                            ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={setCurrentPage}
                                />
                            </ul>
                        </>
                    }


                </div>
            </div>
        </div>
    );
};
export default OperatorActivity;