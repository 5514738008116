import React, { useState } from 'react';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
// import Loading from '../../Component/Loading/Loading';
import checkImg from '../../../src/assets/img/check.png';
import errorImg from '../../../src/assets/img/uncheck.png';

const CheckBoxPopup = (props) => {
    const userData = useSelector(state => state.LoginReducer.payload);

    const [toggleZipcodePopupOpen, setToggleZipcodePopupOpen] = useState(true);
    const [alertimg, setAlertImg] = useState("");

    const [popupTitle, /* setPopupTitle */] = useState("");
    const [popupMsg, /* setPopupMsg */] = useState("");
    const [popupType, /* setPopupType */] = useState("");
    const [popupActionType, /* setPopupActionType */] = useState("");
    const [popupActionValue, /* setPopupActionValue */] = useState("");

    const [alertmessage, setAlertMessage] = useState("");
    const [alerttitle, setAlertTitle] = useState("");
    // const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    // const [loading, /* setLoading */] = useState(true)

    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    // const getSelectedReport = () => {

    //     //  let time = assignDate + " 10:00:00";

    //     console.log("check the request", request);


    //     API.post('assigncallqueueAddlist/add ', request).then((response) => {
    //         if (response.data.success == true) {
    //             togglePopup();
    //             setPopupTitle("Call Queue");
    //             setPopupMsg("Call Queue Added Succesfully");
    //             setPopupType("success");
    //             setPopupActionType("redirect");
    //             setPopupActionValue("ok");
    //             setPopupActionPath("/Callqueue");
    //         }
    //         else {
    //             togglePopup();
    //             setPopupTitle("Call Queue");
    //             setPopupMsg(response.data.error.err);
    //             setPopupType("error");
    //             setPopupActionType("close");
    //             setPopupActionValue("close");
    //         }
    //     });
    // };

    console.log("selcted", props.PageselectAll, props.PageselectAll === false, props.PageselectAll ==="");


    const getSelectedReport = () => {

        var toUTC = new Date(props.assignDate + " 10:00:00").toUTCString()
        console.log(toUTC, props.assignDate + " 10:00:00", "utc");

        let request = {
            customer_id:  props.PageselectAll === false || props.PageselectAll ===""? props.selectedValues.map(data => data.customer_id) : props.FilterSelect,
            customermap_id: props.PageselectAll === false || props.PageselectAll ===""? props.selectedValues.map(data => data.customermap_id) : props.FilterMapid,
            operator_id: props.selectedOperatorName.operator_id,
            assign_date: props.assignDate,
            active: 1,
            createdBy: userData.admin_id,
            customer_map_status: "ADMIN",
            updatedBy: userData.admin_id,
            type: props.PageselectAll === false || props.PageselectAll ===""? props.selectedValues.map(data => data.type) : props.FilterSelectType,
        };
        console.log("selcted", props, request);

        API.post("assigncallqueueAddlist/add", request)
            .then((response) => {
                if (response.data.success == true) {
                    setToggleZipcodePopupOpen(false);
                    setAlertTitle("Call Queue");
                    setAlertMessage("Call Queue Added Succesfully");
                    setAlertImg(checkImg);
                    props.reCallFunction();

                } else {
                    setAlertTitle("Call Queue");
                    setAlertImg(errorImg);
                    setAlertMessage(response.data.error.err);
                }
            });
    };

    const handleClose = () => {
        props.toggle();
        props.reCallFunction();
    }

    return (

        <div>
            <div>
                {toggleZipcodePopupOpen ?
                    (<>
                        <div className="main-contents">
                            <div className="modal-bodys">
                                {/* {loading ? <Loading /> : */}
                                <div className="row">
                                    <div className="back-heading">
                                        <h5 className="heading-text">Confirmation</h5>
                                        <p className='Para-text'>Are You Sure, Do you want to Add this customer in Call Queue</p>
                                    </div>
                                </div>
                                {/* } */}

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-smloutline-danger me-2" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                                    <button type="button" className="btn form-submit-btn" onClick={getSelectedReport}>Save </button>
                                </div>
                            </div>
                        </div>
                    </>) :
                    (<div className='uploadpopup'>
                        <div className="Commonfull-icon">
                            <img className={alertimg === checkImg ? "successImg " : "errorImg"} src={alertimg} />
                        </div>
                        <div className="CommonModalbody">
                            <h2>{alerttitle}</h2>
                            <p>{alertmessage}</p>
                        </div>
                        <div className="CommonModalfooter ">
                            <div className="CommonModalfooter session">
                                <button className="btn btn-smlprimary" onClick={props.toggle}>Ok</button>
                            </div>
                        </div>
                    </div>
                    )}
            </div>
            {
                isOpenPopup && (
                    <CommonPopup
                        handleClose={togglePopup}
                        popupTitle={popupTitle}
                        popupMsg={popupMsg}
                        popupType={popupType}
                        popupActionType={popupActionType}
                        popupActionValue={popupActionValue}
                    // popupActionPath={popupActionPath}
                    />
                )
            }
        </div>
    );
};

export default CheckBoxPopup