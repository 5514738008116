import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
//import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';
import moment from 'moment';



const AssignWebphoneConfig = () => {
    const [adminInfo, setAdminInfo] = useState("");
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [adminName, setAdminName] = useState("");
    const [userName, setUserName] = useState("");
    const [Phone, setPhone] = useState("");
    const [PageNn, setPageNo] = useState("");
    const [Count, setCount] = useState("");
    const [status, setStatus] = useState("");

    const pageLimit = PageNn;

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        AssignwebphoneconfigList();
    }, [])
    // ============================List===============================
    const AssignwebphoneconfigList = () => {
        API.post('assignwebphoneconfigList/condition',).then((response) => {
            if (response.data.success == true) {
                setAdminInfo(response.data?.data);
                setTotalRecords(response.data.data?.length);
                setCount(response.data.data1[0].count);
                document.getElementById("page").value = "10";
                setCurrentPage(1);
                setLoading(false);
                setPageNo(10);
            }
        });
    }

    // =========================Search============================

    const onSearch = (e) => {
        setSearch(e.target.value);
    }

    const adminSearch = () => {
        let request = {
            data: search
        }

        API.post("assignwebphoneconfigSearch/condition", request).then(response => {
            setAdminInfo(response.data?.data);
            setCount(response.data.data1[0].count);
            setTotalRecords(response.data.data?.length);
            setCurrentPage(1);
        });
    }

    useEffect(() => {
        if (search != "") {
            const timer = setTimeout(() => {
                adminSearch();
            }, 200);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setLoading(true);
            AssignwebphoneconfigList();
        }
    }, [search]);

    const onKeydownAccountSearch = (event) => {
        if (event.key === "Enter") {
            adminSearch();
        }
    };
    //const yesterday = moment().subtract(1, 'day');
    // const disablePastDt = current => {
    //     return current.isSameOrAfter(fromdate);
    // };

    // const inputProps = {
    //     placeholder: "From Date",
    //     value: fromdate
    // };
    // const inputProps1 = {
    //     placeholder: "To Date",
    //     value: todate
    // };

    // const fromDate = (event) => {
    //     if (event._isAMomentObject == true) {
    //         setfromdate(event.format("YYYY-MM-DD"))
    //         settodate("");
    //     }
    // }
    // const toDate = (event) => {
    //     if (event._isAMomentObject == true) {
    //         settodate(event.format("YYYY-MM-DD"))
    //     }
    // }

    const adminFilter = () => {
        //  setFromDateError("")


        setLoading(true)
        let request = {
            admin_name: "",
            operator_name: adminName,
            username: userName,
            phone_no: Phone,
            email: "",
            active: status
        }

        API.post("assignwebphoneconfigfilter/condition", request).then(response => {
            setAdminInfo(response.data?.data);
            setTotalRecords(response.data.data?.length);
            setCount(response.data.data1[0].count);
            setLoading(false)
            document.getElementById("page").value = "10";
            setCurrentPage(1);
            setPageNo(10);
        });
    }

    const handleSearch = () => {
        adminFilter();
    }

    const handleclear = () => {

        setAdminName("");
        setUserName("");
        setPhone("");
        setStatus("");
    
        const statusSelect = document.getElementById("status");
        statusSelect.selectedIndex = 0;
        //setPageNo("");
        setLoading(true);
        AssignwebphoneconfigList();

        document.getElementById("page").value = "10";
    }

    function formatMobileNO(value) {
        var x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        // console.log("value of x", x);
        value = '+1 ' + '(' + x[1] + ') ' + x[2] + '-' + x[3];
        //console.log("mobileno", value);
        return value;
    }
    const redirection = (id) => {

        navigate({ pathname: "/AdminView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };

    //asperpage

    //sorting

    const [sorted, setSorted] = useState({ sorted: "id", reversed: false })

    const sortById = () => {
        console.log("sortById start");
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.assign_webphone_config_id - userB.assign_webphone_config_id;
            }
            return userB.assign_webphone_config_id - userA.assign_webphone_config_id;
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "id", reversed: !sorted.reversed });
    };

    const sortByUserName = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.username.localeCompare(userA.username);
            }
            return userA.username.localeCompare(userB.username);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "username", reversed: !sorted.reversed });
    };
    const sortByServerAddress = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.serveraddress.localeCompare(userA.serveraddress);
            }
            return userA.serveraddress.localeCompare(userB.serveraddress);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "serveraddress", reversed: !sorted.reversed });
    };

    const sortByOperatorName = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.operatorname.localeCompare(userA.operatorname);
            }
            return userA.operatorname.localeCompare(userB.operatorname);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "username", reversed: !sorted.reversed });
    };

    const sortByCreatedName = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.adminname.localeCompare(userA.adminname);
            }
            return userA.adminname.localeCompare(userB.adminname);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "adminname", reversed: !sorted.reversed });
    };






    //   const sortByEmail = () => {
    //     const usersCopy = [...adminInfo];
    //     usersCopy.sort((userA, userB) => {
    //       if (sorted.reversed) {
    //         return userB.operatoremail.localeCompare(userA.operatoremail);
    //       }
    //       return userA.operatoremail.localeCompare(userB.operatoremail);
    //     });
    //     setAdminInfo(usersCopy);
    //     setSorted({ sorted: "email", reversed: !sorted.reversed });
    //   };



    const sortByPhone = () => {
        console.log("sortById start");
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.operatorPhone - userB.operatorPhone;
            }
            return userB.operatorPhone - userA.operatorPhone;
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "phone", reversed: !sorted.reversed });
    };

    const sortByDateTime = () => {
        console.log("sortById start");
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.createdtime.localeCompare(userA.createdtime);
            }
            return userA.createdtime.localeCompare(userB.createdtime);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "datetime", reversed: !sorted.reversed });
    };

   function formatTime(value) {
        let local = ""
       // let offset = ""
        // let utc1 = ""
       // console.log(value, "yyyy1");
       value = value.replace(" ","T")
       value = value+"Z";
       console.log(value, "yyyy1");
        local = new Date(value);
       value =local.toLocaleString();
        //var local = new Date("2023-05-22 08:21:11");
        // offset = local.getTimezoneOffset();
        // value = new Date(local.getTime() - offset * 60000);
        // console.log(value, "yyy");
         value = moment(value).format('MMMM D, YYYY hh:mm A')
        // let utc2222 = utc1
        console.log(value, "yyyy");
        return value;
    }





    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <h5 className="heading-text">Assign WebphoneConfig</h5>

                    <div className="row">
                        <div className="col-xl-8">
                            <div className="common-heading-sec">
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={adminName} placeholder="Operator Name" onChange={(e) => setAdminName(e.target.value)} />
                                </div>
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={Phone} placeholder="Phone" onChange={(e) => setPhone(e.target.value)} />
                                </div>
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={userName} placeholder="Webphone Username" onChange={(e) => setUserName(e.target.value)} />
                                </div>

                                <div className="col-lg-2 me-2">
                                        {/* <label htmlFor="status" className="col-form-label">Status <span className='mandatory ms-1'>*</span></label> */}
                                        <select className="form-select" id="status" onChange={(e) => setStatus(e.target.value)}>
                                            <option selected style={{ display: "none" }}  >Status</option>
                                            <option value="1">Active</option>
                                            <option value="4">Inactive</option>
                                        </select>
                                        </div>


                                <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                                <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>
                            </div>
                        </div>

                        <div className='col-xl-4'>
                            <div className='add-section'>
                                <div className="search-bar">
                                    <input type="search" className="form-control" value={search} placeholder="User Name or Id  " onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                                </div>
                                <div className="add-btn" onClick={() => navigate('/AssignWebphoneConfigAdd')}><i className="fa-solid fa-plus"></i>Add Assign</div>
                            </div>
                        </div>
                    </div>


                    <div className="common-heading-sec col-lg-12 pageno">
                        <span className="row-per-page">Rows per page:
                            <span>
                                <div>
                                    <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                    <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </span>
                            <div className="count">
                                <span> Count :{Count}</span>
                            </div>
                        </span>


                    </div>

                    {loading ? <Loading /> :
                        <>
                            <div className="rounded table-responsive">

                                <table className="table bg-white">
                                    <thead>
                                        <tr>
                                            <th onClick={sortById}>#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByUserName}>User Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByServerAddress}>Server Address<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByOperatorName}>Operator Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByPhone}>Phone#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByDateTime}>Date & Time<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th  onClick={sortByCreatedName}>Created Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th>Status</th>
                                            <th className='text-center action-right-fix-list'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {adminInfo.length > 0 ? adminInfo.sort((a, b) => b.adminInfo - a.adminInfo).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                            .map((adminInfo, index) =>
                                                <tr key={index}>
                                                    <td>{adminInfo.assign_webphone_config_id}</td>

                                                    <td>{adminInfo.username}</td>
                                                    <td>{adminInfo.serveraddress}</td>
                                                    <td>{adminInfo.operatorname}</td>


                                                    <td>{formatMobileNO(adminInfo.operatorPhone)}</td>
                                                    <td>{formatTime(adminInfo.createdtimes)}</td>
                                                    {/* <td>{adminInfo.createdtime}</td> */}
                                                    <td><a onClick={() => redirection(adminInfo.admin_id)}>{adminInfo.adminname}</a></td>
                                                    <td>{adminInfo.webphoneconfigactive === 1 ?
                                                        <span className="active-color">Active</span> :
                                                        <span className="inactive-color">Inactive</span>
                                                    }</td>
                                                    <td className='text-center action-right-fix-list'><a className="edit me-2" onClick={() => navigate('/AssignWebphoneConfigEdit', ls.set("webphone_config_id", adminInfo.assign_webphone_config_id))}>Edit</a>
                                                        <a className="edit" onClick={() => navigate('/AssignWebphoneConfigView', ls.set("webphone_config_id", adminInfo.assign_webphone_config_id))}>View</a></td>

                                                </tr>
                                            ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={setCurrentPage}

                                />
                            </ul>
                        </>
                    }


                </div>
            </div>
        </div>
    );
};
export default AssignWebphoneConfig;