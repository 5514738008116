import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';




const ListAdd = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [Listname, setListname] = useState("");
    const [Description, setDescription] = useState("");

    // errormsg
    const [ListnameError, setListnameError] = useState("");


    const [CompanyName, setCompanyName] = useState("");
     const [CompanyId, setCompanyId] = useState("");
     const [CompanyNameError, setCompanyNameError] = useState("");
     const [selectedCustomerName, setSelectedCustomerName] = useState("");
  

    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };


    const handleAdd = () => {
        setCompanyNameError("");
        setListnameError("");
        
        if (!CompanyId) {
            setCompanyNameError("Company Name is required");
            return;
        }
        if (!Listname) {
            setListnameError("List Name is required");
            return;
        }
        

        let request = {
            
            list_name: Listname,
            description: Description,
            company_id: selectedCustomerName.company_id,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        };
        API.post('customerlist/add ', request).then((response) => {
            if (response.data.success == true) {
                // const { data } = response;
                togglePopup();
                setPopupTitle("Add New Listname");
                setPopupMsg("Listname Created Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/Listname");
            }
            else {
                // setTimeout(() => {

                //   }, 100);
                togglePopup();
                setPopupTitle("Add New ListName");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

    useEffect(() => {
        handleCompanyName();
    }, [])
    const handleCompanyName = () => {

        API.post("mapcompanyList/condition").then(response => {
            setCompanyName(response.data.data);
        });
    }

    const handleCompany = (e) => {
        setCompanyId(e.target.value);
        const selectedCustomerDetails = CompanyName.filter(data => data.company_id == e.target.value)[0]
        setSelectedCustomerName(selectedCustomerDetails);

    }




    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/ListName')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Add New List Name</h5>
                        </div>



                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                <form>
                                    <div className="row">

                                    <div className="col-lg-4 ">
                                            <label htmlFor="customerName" className="col-form-label">Company Name <span className='mandatory ms-1'>*</span></label>
                                            <select className="form-select" id="customerName" onChange={handleCompany} >
                                                <option style={{ display: "none" }}>Select CompanyName</option>
                                                {CompanyName.length > 0 &&
                                                    <>
                                                        {CompanyName.map((data) => <option key={data.company_id} value={data.company_id} >{data.company_name}</option>)}
                                                    </>
                                                }
                                            </select>
                                            <p className="form-input-error">{CompanyNameError}</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="Listname" className="col-form-label">List Name <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="Listname" onChange={(e) => setListname(e.target.value)} />
                                            <p className="form-input-error">{ListnameError}</p>
                                        </div>

                                        <div className="col-lg-4">

                                            <label htmlFor="first-name" className="col-form-label">Description </label>
                                            <input type="text" className="form-control" id="Description" onChange={(e) => setDescription(e.target.value)} />
                                        </div>

                                        

                                    </div>
                                </form>
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleAdd}>Submit</button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default ListAdd;