import React, { useState } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';



const CustomerCountPageAdd = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [Calllimit, setCalllimit] = useState("");
    const [Count, setCount] = useState("");

     console.log(Count,"count");

    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    

    const handleAdd = () => {
       
        
        let request = {
           
            calllimit: Calllimit,
            assign_value: Count,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        };
        API.post('calllimit/add ', request).then((response) => {
            if (response.data.success == true) {
                // const { data } = response;
                togglePopup();
                setPopupTitle("Add New Config");
                setPopupMsg("Config Created Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/CustomerCountPage");
            }
            else {
                // setTimeout(() => {
              
                //   }, 100);
                togglePopup();
                setPopupTitle("Add New Admin");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

 
   

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">
                   
                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/CustomerCountPage')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Add New Config</h5>
                        </div>



                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                       

                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">Call Limit <span className='mandatory ms-1'>*</span></label>
                                            <input type="calllimit" className="form-control" id="calllimit" onChange={(e) => setCalllimit(e.target.value)} />
                                           
                                        </div>

                                        <div className="col-lg-4">
                                      
                                        <label htmlFor="first-name" className="col-form-label">Total Count </label>
                                            <input type="Count" className="form-control" id="Count" onChange={(e) => setCount(e.target.value)}/>
                                        </div>
                                        
                                       
                                        
                                       
                                        
                                    </div>
                                </form>
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleAdd}>Submit</button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default CustomerCountPageAdd;