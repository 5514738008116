import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
 //import { useSelector } from 'react-redux';
//import CommonPopup from '../../Component/CommonPopup/CommonPopup';
//import Datetime from 'react-datetime';
//import moment from 'moment';

const CallqueueView = () => {
    const navigate = useNavigate();
   // const userData = useSelector(state => state.LoginReducer.payload);
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    const schedule_callsid = location.state.schedulecallsid;
    const [customerId, setCustomerId] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [, setSelectedCustomerName] = useState("");
    const [operatorId, setOperatorId] = useState("");
    const [operatorName, setOperatorName] = useState("");
    const [, setSelectedOperatorName] = useState("");
    const [assignDate, setAssignDate] = useState("");
    //const [comments, setComments] = useState("");
    const [Time, setTime] = useState("");
    //const [operatorcustomermapid, setoperatorcustomermapid] = useState("");

    // const [customerNameError, setCustomerNameError] = useState("");
    // const [operatorNameError, setOperatorNameError] = useState("");
    // const [assignDateError, setAssignDateError] = useState("");
    // const [TimeError, setTimeError] = useState("");

    // const [popupTitle, setPopupTitle] = useState("");
    // const [popupMsg, setPopupMsg] = useState("");
    // const [popupType, setPopupType] = useState("");
    // const [popupActionType, setPopupActionType] = useState("");
    // const [popupActionValue, setPopupActionValue] = useState("");
    // const [popupActionPath, setPopupActionPath] = useState("");
    //const [isOpenPopup, setIsOpenPopup] = useState(false);
    // const togglePopup = () => {
    //     setIsOpenPopup(!isOpenPopup);
    // };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        handleCustomerName();
        handleOperatorName();
        CustomersMapView();
    }, [])

    const CustomersMapView = () => {

        let request = {
            schedule_calls_id: schedule_callsid
        }

        API.post("operatorreschedulelistbyid/condition", request).then(response => {
            // setCustomer(response.data.data[0]?.customer_name)
            // setOperator(response.data.data[0]?.operator_name)
            setOperatorId(response.data.data[0]?.operator_id);
            setCustomerId(response.data.data[0]?.customer_id)
            setAssignDate(response.data.data[0]?.assign_date);
           /// setComments(response.data.data[0]?.comments);
            setTime(response.data.data[0]?.assign_time);
            setTimeout(() => {
                setLoading(false);
            }, 800);
        });
    }

    const handleCustomerName = () => {

        API.post("mapCustomerlist/condition").then(response => {
            setCustomerName(response.data.data);
        });
    }
    const handleCustomerNameId = (e) => {
        setCustomerId(e.target.value);
        const selectedCustomerDetails = customerName.filter(data => data.customer_id == e.target.value)[0]
        setSelectedCustomerName(selectedCustomerDetails);

    }

    const handleOperatorName = () => {

        API.post("mapOperatorlist/condition").then(response => {
            setOperatorName(response.data.data);
        });
    }
    const handleOperatorNameId = (e) => {
        setOperatorId(e.target.value);
        const selectedLangDetails = operatorName.filter(data => data.operator_id == e.target.value)[0]
        setSelectedOperatorName(selectedLangDetails);
    }

   


    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content callqueue">

                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/Reschedule')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">VIEW RESCHEDULE</h5>
                        </div>
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                {loading ? <Loading /> :
                                    <form>
                                        <div className="row ">
                                            <div className="col-lg-4 ">
                                                <label htmlFor="customerName" className="col-form-label">Customer Name <span className='mandatory ms-1'>*</span></label>
                                                <select className="form-select" id="customerName" value={customerId} onChange={handleCustomerNameId} disabled>
                                                    {/* <option style={{ display: "none" }}>{customerId}</option> */}

                                                    {customerName.length > 0 &&
                                                        <>
                                                            {customerName.map((data) => <option key={data.customer_id} value={data.customer_id} >{data.customer_name}</option>)}
                                                        </>
                                                    }
                                                </select>
                                               
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="operatorName" className="col-form-label">Operator Name <span className='mandatory ms-1'>*</span></label>
                                                <select className="form-select" id="operatorName" value={operatorId} onChange={handleOperatorNameId} disabled>
                                                    {/* <option style={{ display: "none" }}>{operatorId}</option> */}
                                                    {operatorName.length > 0 &&
                                                        <>
                                                            {operatorName.map((data) => <option key={data.operator_id} value={data.operator_id} >{data.operator_name}</option>)}
                                                        </>
                                                    }
                                                </select>
                                               
                                            </div>
                                            {/* <div className="col-lg-4">
                                                <label htmlFor="assignDate" className="col-form-label" >AssignDate <span className='mandatory ms-1'>*</span></label>
                                                <input type="date" className="form-control" id="assignDate" value={assignDate} onChange={(e) => setAssignDate(e.target.value)} />
                                                <p className="form-input-error" >{assignDateError}</p>
                                            </div> */}
                                            <div className="col-lg-4 form-group datePickerBlock ">
                                            <div className="tbox">
                                                <div className="textbox">
                                                    <label htmlFor="assignDate" className="col-form-label" >AssignDate <span className='mandatory ms-1'>*</span></label>
                                                    <input type="text" className="form-control" id="password" value={assignDate} disabled/>

                                                  
                                                </div>
                                            </div>
                                        </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="time" className="col-form-label" >Time<span className='mandatory ms-1'>*</span></label>
                                                <input type="time" className="form-control" id="time" value={Time}  disabled />
                                            
                                            </div>
                                            {/* <div className="col-lg-4">
                                                <label htmlFor="comments" className="col-form-label" >Comments</label>
                                                <input type="text" className="form-control" id="comment" defaultValue={comments} onChange={(e) => setComments(e.target.value)} />
                                            </div> */}
                                        </div>
                                    </form>
                                }
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
           
        </div>
    );
};
export default CallqueueView;