import React, { useState , useEffect} from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
//import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Loading from '../../Component/Loading/Loading';
//import ls from 'local-storage';


const ConfigEdit = () => {
   // const AdminId = ls.get("AdminId");
   // const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [calllimit, setcalllimit] = useState("");
  

    const [Count, setCount] = useState("");
    console.log("calllimit",calllimit);
    const [CallLimitError, setCallLimitError] = useState("");
    const [AssignLimitError, setAssignLimitError] = useState("");

   // const [operatorID, setoperatorID] = useState("");
   // console.log(operatorID,"operatorID");
  
    const [calllimitID, setcalllimitID] = useState("");


    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, /* setPopupActionPath */] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [loading, setLoading] = useState(true)

   
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
   

    useEffect(() => {
        Configview();
    }, [])

    const Configview = () => {
        
        API.post('calllimit/condition ').then((response) => {
            if (response.data.success == true) {
                setcalllimit(response.data.data[0]?.calllimit);
                setcalllimitID(response.data.data[0]?.calllimit_id);
                setCount(response.data.data[0]?.assign_value);
                setLoading(false);
            }
        });
    }


    const handleAdd = () => {

        setCallLimitError("");
        setAssignLimitError("");
       if(!calllimit)
       {
        setCallLimitError("CallLimit is required");
        return;
       }
       if(!Count)
       {
        setAssignLimitError("Assign Count is required");
        return;
       }
        
        let request = {
           
            calllimit: calllimit,
            assign_value: Count,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        };
        API.post('calllimit/add ', request).then((response) => {
            if (response.data.success == true) {
                // const { data } = response;
                togglePopup();
                setPopupTitle("Add  CallLimit");
                setPopupMsg("CallLimit Created Succesfully");
                setPopupType("success");
                setPopupActionType("close");
                setPopupActionValue("close");
                setLoading(true);
            }
            else {
                // setTimeout(() => {
              
                //   }, 100);
                togglePopup();
                setPopupTitle("Add  CallLimit");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

    const handleEdit = () => {
        setCallLimitError("");
        setAssignLimitError("");
       if(!calllimit)
       {
        setCallLimitError("CallLimit is required");
        return;
       }
       if(!Count)
       {
        setAssignLimitError("Assign Count is required");
        return;
       }
        
        
        let request = {

            calllimit: calllimit,
            assign_value :Count,
            calllimit_id: calllimitID,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        };
        API.post('calllimit/update', request).then((response) => {
            if (response.data.success == true) {
                // const { data } = response;
                togglePopup();
                setPopupTitle("edit  CallLimit");
                setPopupMsg("CallLimit Updated  Succesfully");
                setPopupType("success");
                setPopupActionType("close");
                setPopupActionValue("close");
                setLoading(true);
                Configview();
              
               
            }
            else {
                // setTimeout(() => {
              
                //   }, 100);
                togglePopup();
                setPopupTitle("edit New Config");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

   

    // const handleOperatorNameId = (e) => {
    //     setoperator(e.target.value);
    //     const selectedLangDetails = operatorID.filter(data => data.operator_id == e.target.value)[0]
    //     setSelectedoperator(selectedLangDetails);
    // }
    //   console.log(Selectedoperator);


    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">
                   
                        <div className="back-heading">
                            <h5 className="heading-text">Call Limit</h5>
                        </div>


                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                            {loading ? <Loading /> :
                                <form>
                                    <div className="row">
                                       


                                        <div className="col-lg-4">
                                            <label htmlFor="first-name" className="col-form-label">call Limit <span className='mandatory ms-1'>*</span></label>
                                            <input type="calllimit" className="form-control" id="calllimit" value={calllimit} onChange={(e) => setcalllimit(e.target.value)} />
                                            <p className="form-input-error">{CallLimitError}</p>
                                        </div>

                                        <div className="col-lg-4">
                                        <label htmlFor="first-name" className="col-form-label">Assign Count <span className='mandatory ms-1'>*</span></label>
                                            <input type="Count" className="form-control" id="Count" value={Count} onChange={(e) => setCount(e.target.value)}/>
                                            <p className="form-input-error">{AssignLimitError}</p>
                                        </div>
                                       
                                        
                                    </div>
                                </form>}
                                <div className="modal-footer">
                                {calllimitID > 0 ? 
                                    <button type="button" className="btn form-submit-btn" onClick={handleEdit}>Update </button>:
                                    <button type="button" className="btn form-submit-btn" onClick={handleAdd}>Add </button>

                                  
                                      } 
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default ConfigEdit;