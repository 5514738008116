import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Loading from '../../Component/Loading/Loading';
import ls from 'local-storage';
import Pagination from 'reactjs-hooks-pagination';


const AssignEdit = () => {
    const companyId = ls.get("company_id");
    const OperatorId = ls.get("operator_id");
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);

    const [PageNn, setPageNo] = useState("");
    const pageLimit = PageNn;
    const [CompanyNameList, setCompanyNameList] = useState("");
    const [OperatorName, setOperatorName] = useState("");
    const [CustomerId, setCustomerId] = useState([]);
    const [customersInfo, setCustomersInfo] = useState("");

    const [newSelect, setNewSelect] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [totalRecords, setTotalRecords] = useState(0);
    const [checkLen, setCheckLen] = useState("")
    const [selectedValues, setSelectedValues] = useState([]);
    const [flag, setFlag] = useState(false)
    const [select, setSelect] = useState(false);
    const [unCheckLen, setunCheckLen] = useState("")
    const [/* status_name */, setstatus_name] = useState("");
    const [len, setLen] = useState("")
    const [, setSelectAll] = useState(false);
    const [page, setPage] = useState("")
    const [selectedPage, setSelectedPage] = useState(false);
    

    let onSelectedData = [];
    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [loading, setLoading] = useState(true)


    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };


    useEffect(() => {
        Assignview();
        //  CustomersListInfo();

    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            CustomersListInfo();
        }, 100);

        return () => {
            clearTimeout(timeoutId);
        }
    }, []);

    const assignDataMap = {};
    const Assignview = () => {
        let request = {
            company_id: companyId,
            operator_id: OperatorId
        };
        API.post('assignlistbyid/condition ', request).then((response) => {
            if (response.data.success == true) {

                setCompanyNameList(response.data.data[0].company_name);
                setOperatorName(response.data.data[0].operatorname);
                setCustomerId(response.data.data)
                setSelectedValues(response.data.data)
                response.data.data.map((assignData) => {
                    console.log(assignData, "assignData.customer_list_id");
                    assignDataMap[assignData.customer_list_id] = assignData;
                });
                setLoading(false);

            }
        });
    }
    console.log(CustomerId, selectedValues, "Sdsdd");

    const handleEdit = () => {
        if (selectedValues.length === 0) {
            // Show a validation message here or set an error state
            togglePopup();
            setPopupTitle("Edit Assign List");
            setPopupMsg("Please select at least one customer to assign.");
            setPopupType("error");
            setPopupActionType("close");
            setPopupActionValue("close");
        } else {
            let request = {
                company_id: companyId,
                operator_id: OperatorId,
                customerlistid: selectedValues,
                active: 1,
                createdBy: userData.admin_id,
                updatedBy: userData.admin_id
            };
            API.post('assignlist/add', request).then((response) => {
                if (response.data.success === true) {
                    togglePopup();
                    setPopupTitle("Edit Assign List");
                    setPopupMsg("Assign List details Updated Successfully");
                    setPopupType("success");
                    setPopupActionType("redirect");
                    setPopupActionValue("ok");
                    setPopupActionPath("/AssignList");
                } else {
                    togglePopup();
                    setPopupTitle("Edit Assign List");
                    setPopupMsg(response.data.error.err);
                    setPopupType("error");
                    setPopupActionType("close");
                    setPopupActionValue("close");
                }
            });
        }
    };
    const CustomersListInfo = () => {
        let request = {
            company_id: companyId,

        };

        API.post('customerlistassgin/condition', request).then((response) => {
            if (response.data.success === true) {
                const updatedCustomersInfo = response.data.data.map((operatorData) => {
                    const assignData = assignDataMap[operatorData.customer_list_id] || null;
                    const isChecked = assignData !== null;
                    const currentPage = isChecked ? assignData.currentPage : "";
                    return { ...operatorData, isChecked, currentPage };
                });
         
                setCustomersInfo(updatedCustomersInfo);
                setTotalRecords(updatedCustomersInfo.length);
                setCurrentPage(1);
                setPageNo(10);
            }
            setCurrentPage(1);
        });
    }

    useEffect(() => {
        if (!flag) {
            setSelect(false)
            console.log("useeffect first if calling");
        } else {
            if ((len !== unCheckLen) && (len !== checkLen)) {
                console.log("useeffect status", len !== unCheckLen);
                setSelect(false)
            } else if (checkLen === len) {
                setSelect(true)
                // setFlag(true)
                console.log("useeffect else if calling");

            } else {
                setSelect(false)
                console.log("useeffect else calling");

            }

        }

    }, [unCheckLen, checkLen])
    console.log("checklen", len, "uncheck", unCheckLen, checkLen, "checkLen");

    useEffect(() => {

        if (currentPage === page && selectedPage === 1) {
            setSelectAll(true)
            setSelect(true)
        }
        else if (currentPage === page) {
            setSelect(true)
        }

        else {
            setSelectAll(false)
            setSelect(false)
        }



    }, [currentPage])
    const handleSelectField = (item, id, flag, crntPage) => {

        console.log("newSelect", newSelect);

        setSelect(!select)
        setFlag(flag)
        onSelectedData.map((data) => { data.customer_list_id })
        // console.log("checkitem", id);
        const checkbox = document.getElementById(id)
        const filter = newSelect.filter(data => data.crntPage === crntPage);
        setNewSelect(filter.length ? newSelect.filter(item => item.crntPage !== crntPage) : [...newSelect, { crntPage }]);

        if (checkbox != null) {

            if (checkbox.checked === true) {
                console.log("if calling");
                const checkPage = checkbox.checked === true ? 1 : ""
                const checkPage1 = currentPage
                setSelectedPage(checkPage)
                setPage(checkPage1)
                console.log("checkitem checkPage", onSelectedData.map((data) => { return { "customer_list_id": data.customer_list_id } }));
                setSelectAll(true)
                setSelect(true)

                const selValues = [...selectedValues, ...onSelectedData.map((data) => { return { "customer_list_id": data.customer_list_id } })]
                const filteredArr = selValues.reduce((acc, current) => {
                    const x = acc.find(item => item.customer_list_id === current.customer_list_id);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);
                setSelectedValues(filteredArr)

                for (let i = 0; i < onSelectedData.length; i++) {
                    console.log("findindex.len", onSelectedData.length);
                    setLen(onSelectedData.length);
                    console.log("findindex i", i);
                    const sorting = customersInfo.sort((a, b) => b.customer_list_id - a.customer_list_id);
                    const findIndex = sorting.findIndex(item => item.customer_list_id === onSelectedData[i].customer_list_id);
                    console.log("findindex", findIndex, sorting);
                    sorting[findIndex].isChecked = true;
                    sorting[findIndex].currentPage = crntPage;
                    setCustomersInfo(sorting);
                }

                // customersInfo.sort((a, b) => b.customer_list_id - a.customer_list_id) 
                // setCustomersInfo(customersInfo.map((data) => { return { ...data, isChecked: true } }))
                // data.customer_list_id
                //setflag(true);
            }
            else {
                console.log("else calling");
                const tem = selectedValues;
                //console.log("elseif calling");
                setSelectAll(false)
                for (let i = 0; i < onSelectedData.length; i++) {
                    for (let j = 0; j < tem.length; j++) {
                        if (tem[j].customer_list_id === onSelectedData[i].customer_list_id) {
                            tem.splice(j, 1);
                        }

                    }
                }

                // setCustomersInfo(customersInfo.map((data) => { return { ...data, isChecked: false } }))
                for (let i = 0; i < onSelectedData.length; i++) {
                    console.log("findindex.len", onSelectedData.length);
                    setLen(onSelectedData.length);
                    console.log("findindex i", i);
                    const sorting = customersInfo.sort((a, b) => b.customer_list_id - a.customer_list_id);
                    const findIndex = sorting.findIndex(item => item.currentPage === onSelectedData[i].currentPage);
                    console.log("findindex", findIndex, sorting);
                    sorting[findIndex].isChecked = false;
                    sorting[findIndex].currentPage = '';
                    setCustomersInfo(sorting);
                }
                // setflag(true);
            }
        }
    };
    const handleSelectChangeField = (items, crntPage) => {


        console.log("items", items);
        setstatus_name(items)
        console.log("check the current pages", onSelectedData);

        const uncheckstatus = selectedValues.filter(data => data.customer_list_id !== items.customer_list_id);
        console.log("customersInfolenght", uncheckstatus.length, customersInfo.length);
        setunCheckLen(uncheckstatus.length)

        if (checkLen !== onSelectedData.length) {
            document.getElementById("SelectAll").checked = false;
            setSelect(false)

            console.log("single uncheck", uncheckstatus.length, onSelectedData.length, "checkLen", checkLen);
        }

        const checkval = selectedValues.filter((data) => data.customer_list_id === items.customer_list_id);

        if (checkval.length > 0) {

            console.log("if check calling");
            const remove = customersInfo.filter(data => data.customer_list_id === items.customer_list_id);
            setNewSelect(newSelect.filter(item => item.crntPage !== remove[0].currentPage));

            const allList = customersInfo;
            console.log("selectedValues", selectedValues);
            const findIndex = allList.findIndex((data) => data.customer_list_id === items.customer_list_id);
            allList[findIndex].isChecked = false;
            allList[findIndex].currentPage = '';
            console.log("findIndex if", findIndex);
            setCustomersInfo(allList);
            setSelectedValues(selectedValues.filter(data => data.customer_list_id !== items.customer_list_id));
            const value = selectedValues.filter(data => data.customer_list_id !== items.customer_list_id).length;
            console.log("value", value);
            setCheckLen(value.length);
            console.log("single selectedValues if", selectedValues.filter(data => data.customer_list_id !== items.customer_list_id).length);

        }

        else {

            console.log("else check calling");
            console.log("selectedValues", selectedValues);
            const allList = customersInfo;
            const findIndex = allList.findIndex((data) => data.customer_list_id === items.customer_list_id);
            // console.log("findIndex else",findIndex);
            allList[findIndex].isChecked = true;
            allList[findIndex].currentPage = crntPage;

            setCustomersInfo(allList);
            setSelectedValues([...selectedValues, { "customer_list_id": items.customer_list_id }])
            const value = [...selectedValues, { "customer_list_id": items.customer_list_id }]
            setCheckLen(value.length);
            console.log("single selectedValues last", value.length);
            // setStatusError("")
            const sellectAllElement = onSelectedData.filter(data => data.isChecked === true)
            if (sellectAllElement.length === onSelectedData.length && crntPage === currentPage) {
                setNewSelect([...newSelect, { crntPage }]);
            }

            console.log("element", sellectAllElement.length === onSelectedData.length && crntPage === currentPage);

        }


    };

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/AssignList')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Edit Assign List</h5>
                        </div>



                        {/* {loading ? <Loading /> : */}
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                {loading ? <Loading /> :
                                    <form>
                                        <div className="row">


                                            <div className="col-lg-6">
                                                <label htmlFor="CompanyNameList" className="col-form-label">Company Name <span className='mandatory ms-1'>*</span></label>
                                                <input type="text" className="form-control" id="CompanyNameList" value={CompanyNameList} disabled />

                                            </div>
                                            <div className="col-lg-6">

                                                <label htmlFor="first-name" className="col-form-label">Operator Name </label>
                                                <input type="text" className="form-control" id="Description" value={OperatorName} disabled />
                                            </div>

                                        </div>
                                        <div className="rounded table-responsive customer-table editassign-table ">
                                            <table className="table bg-white">
                                            <thead className='assign-thead'>
                                                    <tr>

                                                        <th  /* onClick={sortByListName} */>List Name{/* <i className="fa fa-sort" aria-hidden="true"></i> */}</th>
                                                        <th><span className='assign-selectalls'>SelectAll</span>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={customersInfo.length > 0 && customersInfo.every(customer => customer.isChecked)}
                                                                id='SelectAll'
                                                                onClick={() => { handleSelectField(customersInfo, "SelectAll", true, currentPage) }}
                                                            />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {customersInfo.length > 0 ? customersInfo.sort((a, b) => b.customersInfo - a.customersInfo).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                                        .map((customersInfo, index) => {
                                                            onSelectedData = [...onSelectedData, customersInfo]
                                                            return (<tr key={index}>

                                                                <td>{customersInfo.list_name}</td>

                                                                <td>
                                                                    <div>

                                                                        <input className="form-check-input" type="checkbox" checked={customersInfo.isChecked} inputId={`selectId${index}`} onClick={() => { handleSelectChangeField(customersInfo, currentPage); }} />
                                                                    </div>
                                                                </td>

                                                            </tr>)
                                                        }
                                                        ) : ""}
                                                </tbody>
                                            </table>
                                        </div>
                                        <ul className="pagination">
                                            <Pagination className=""
                                                totalRecords={totalRecords}
                                                pageLimit={pageLimit}
                                                pageRangeDisplayed={1}
                                                onChangePage={setCurrentPage}
                                            />
                                        </ul>
                                    </form>
                                }
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleEdit}>Submit</button>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default AssignEdit;